import React from "react";
import "../style/components/ReservationSession.css";
import { IMAGES } from "../utils/utils";

function ReservationSession({ reservation }) {
  const { backgroundImage, heading, bodyText } = reservation;
  return (
    <div className="reservation-session">
      <img
        className="background-image"
        style={{ filter: "brightness(75%)" }}
        src={backgroundImage === "home" ? IMAGES.RESERVATION_BG : ""}
        alt="reservation-background"
      />
      <div className="reservation-container">
        <h1 style={{ color: "white" }}>{heading}</h1>
        <a
          href="tel:0866810736"
          className="custom-button-link"
          style={{ color: "white" }}
        >
          {bodyText}
        </a>
      </div>
    </div>
  );
}

export default ReservationSession;
