import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ingredientByLocationApi,
  ingredientDetailsApi,
  locationApi,
} from "./ingredient.wpapi";

export const fetchIngredientLocations = createAsyncThunk(
  "ingredient-location/get",
  async () => {
    const response = await locationApi();
    return response;
  }
);

export const fetchIngredientPreview = createAsyncThunk(
  "ingredient-preview/get",
  async (location) => {
    const response = await ingredientByLocationApi(location);
    return response;
  }
);

export const fetchIngredientDetails = createAsyncThunk(
  "ingredient-detail/get",
  async (id) => {
    if (id) {
      const response = await ingredientDetailsApi(id);
      return response;
    } else {
      return false;
    }
  }
);
