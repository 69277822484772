import {
  BLOG,
  COOKING_TIPS_BLOG,
  LOCAL_FOOD_BLOG,
  NEWS_BLOG,
} from "../api/utils.api";
import {
  getAllContent,
  getImagefromContent,
  getMultiLanguagePostIds,
  getMultilanguageCategories,
} from "../api/utils.reducer";
import {
  elementToString,
  formatDateMonthYear,
  hasData,
  imageWithDefault,
  toElement,
} from "./utils";

export const TEXT_VN = {
  heading: "Cơm ngon nhờ có::bí quyết",
  share: "Share:",
  more: "Các bài viết khác",
};

export const TEXT_EN = {
  heading: "Our::secrets::for a delicious meal",
  share: "Share:",
  more: "Other articles",
};

export const BLOG_LANDING_TEXT = "Vẹn nguyên hương vị tô cơm nhà";

export const CATEGORIES = [
  { id: BLOG, name: "default", label: { vn: "Tất cả bài viết", en: "All" } },
  { id: NEWS_BLOG, name: "news", label: { vn: "Tin tức", en: "News" } },
  {
    id: COOKING_TIPS_BLOG,
    name: "cooking-tips",
    label: { vn: "Mẹo nấu ăn", en: "Cooking tips" },
  },
  {
    id: LOCAL_FOOD_BLOG,
    name: "local-food",
    label: { vn: "Ẩm thực vùng miền", en: "Local food" },
  },
];

export const getPreviewImages = (blogs, language) => {
  return blogs
    .map((blog) => blog[language])
    .map((blog) => getImagefromContent(blog.content)[0]);
};

const getBlogHeading = (article, language) => {
  const [first, second] = getAllContent(article[language].content);

  const heading = first.type === "figure" ? second : first;
  return heading.props.children;
};

export const getFormattedBlog = (blog, language, previewImage) => {
  const formattedBlog = {
    ...blog,
    categoryDisplayName: getMultilanguageCategories(blog.categories, language),
    heading: getBlogHeading(blog, language),
    previewImage: imageWithDefault(previewImage),
    date: formatDateMonthYear(blog.date),
    postID: getMultiLanguagePostIds(blog),
  };
  return formattedBlog;
};

export const getFormattedDetails = (blog, language) => {
  const category = getMultilanguageCategories(blog.categories, language);

  //if the first block is an image - remove from content
  const content = getAllContent(blog.content);
  let newContent = content;
  if (content[0].type === "figure") {
    newContent = content.slice(1);
  }
  //if the first block is an image - use as preview image
  //if he first block is not an image - get the first image found
  const images = getImagefromContent(blog.content);
  const imagePreview = imageWithDefault(images[0]);
  const image = hasData(images) && images.slice(1);
  const contentParagraph = newContent
    .slice(1)
    .map((content) => elementToString(content));

  return {
    ...blog,
    heading: newContent[0].props.children,
    category,
    imagePreview,
    image,
    date: formatDateMonthYear(blog.date),
    contentParagraph,
  };
};

export const getFormattedCategories = (blogs, language) => {
  const blogCategories = [
    ...new Set(blogs.map((blog) => blog.categories).flat()),
  ];
  const formattedCategories = CATEGORIES.filter((category) =>
    blogCategories.includes(category.id)
  ).map((category) => ({
    ...category,
    label: category.label[language],
  }));

  return formattedCategories;
};

//blog detail methods
export const getFullWidthImage = (content, fullWidthIndex) => {
  const contentElement = content.map((cont) => toElement(cont));
  const figures = contentElement.filter(({ type }) => type === "figure");
  const imageElements = figures.filter(
    ({ props }) => props.children.type === "img"
  );
  const images = imageElements.map(({ props }) => props.children);
  const imageIDs = images.map(({ props }) => props["data-attachment-id"]);

  const fullWidthImage = imageIDs[fullWidthIndex - 1];

  return fullWidthImage;
};
