import { useMediaQuery } from "@mui/material";
import React from "react";
import { breakpoint } from "../../utils/responsiveUtils";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import XoiComHeading from "../generics/XoiComHeading";
import TextParagraph from "./TextParagraph";
import PieceImage from "./PieceImage";
import { SPACE_IMAGES } from "../../utils/spaceUtils";

const { PIECE_1, PIECE_2, PIECE_3, PIECE_4, PIECE_5 } = SPACE_IMAGES;

function PieceSession({ backgroundColor, content }) {
  const mobile = useMediaQuery(breakpoint.mobile);
  const medium = useMediaQuery(breakpoint.medium);
  const xlarge = useMediaQuery(breakpoint.xlarge);
  const spaceContent = useSelector((state) => state.reducer.spaceContent);
  const { headingText, content: contentParagrah } = content;

  return (
    <motion.div className="space-piece-session" style={{ backgroundColor }}>
      <div className="space-piece-heading-1">
        <div className={!mobile && "space-piece-heading"}>
          <XoiComHeading heading={headingText} />
        </div>
        <div
          className="space-piece-card"
          style={{ width: medium ? (xlarge ? "36vw" : "39vw") : "100%" }}
        >
          <TextParagraph
            content={contentParagrah[0]}
            bodyClass="space-piece-card-bodytext"
            style={{ textAlign: "left" }}
          />
        </div>
      </div>
      <div className="space-piece-container-2">
        <PieceImage className="space-image-1" imageSrc={PIECE_1} />
        <PieceImage className="space-image-2" imageSrc={PIECE_2} />
      </div>
      <div className="space-piece-container-3">
        <div className="space-piece-card">
          <TextParagraph
            content={contentParagrah[1]}
            className="space-piece-card-bodytext"
            style={{ textAlign: "left" }}
          />
        </div>
        {mobile ? (
          <motion.div className="space-image-container">
            <PieceImage containterClass="space-image-4" imageSrc={PIECE_4} />
            <PieceImage className="space-image-5" imageSrc={PIECE_5} />
          </motion.div>
        ) : (
          <PieceImage containterClass="space-image-3" imageSrc={PIECE_3} />
        )}
      </div>
      <div className="space-piece-container-4">
        {mobile ? (
          <PieceImage containterClass="space-image-3" imageSrc={PIECE_3} />
        ) : (
          <motion.div className="space-image-container">
            <PieceImage containterClass="space-image-4" imageSrc={PIECE_4} />
            <PieceImage className="space-image-5" imageSrc={PIECE_5} />
          </motion.div>
        )}
        <div className={`space-piece-card ${!mobile && "card-right"}`}>
          <TextParagraph
            content={contentParagrah[2]}
            bodyClass="space-piece-card-bodytext"
          />
        </div>
      </div>
      {spaceContent.heading && spaceContent.subText && (
        <div
          className="space-piece-heading-2 space-community-text-container"
          style={{ padding: mobile ? "72px 0" : "" }}
        >
          <XoiComHeading heading={spaceContent.heading} />
          <div className="body-b1">{spaceContent.subText}</div>
        </div>
      )}
    </motion.div>
  );
}

export default PieceSession;
