import { createAsyncThunk } from "@reduxjs/toolkit";
import { historyApi } from "./history.wpapi";

export const fetchHistoryArticles = createAsyncThunk(
  "history/get",
  async () => {
    const response = await historyApi();
    return response;
  }
);
