import {
  INGREDIENT,
  getPostsByLanguage,
  getMultiLanguagePostMapping,
  fetchByCategory,
  fetchByCategoryAndId,
  fetchCategories,
  getIngredientCategoryNames,
} from "../utils.api";

export const locationApi = async () => {
  const data = await fetchCategories();
  const ingredientCategories = getIngredientCategoryNames(data, "ingredient");

  //get all results
  return ingredientCategories;
};

export const ingredientByLocationApi = async (location) => {
  const ingredientLocations = `${location}-ingredient`;
  const [locationCategory] = await fetchCategories(
    `slug=${ingredientLocations}`
  );
  const locationId = locationCategory.id;

  const data = await fetchByCategory(locationId);

  //map vn-en version
  const dataNoTranslate = getPostsByLanguage(data);
  const titles = dataNoTranslate.map((post) => post.slug);
  const postPairs = dataNoTranslate.map((post, index) => ({
    id: post.id,
    location,
    ingredient: post.slug,
    vn: post,
    ...getMultiLanguagePostMapping(post, data, titles[index]),
  }));

  //get all results
  return postPairs;
};

//get ingredient-details
export const ingredientDetailsApi = async (ingredientId) => {
  const data = await fetchByCategoryAndId(INGREDIENT, ingredientId);
  const { id, slug, content } = data;
  const ingredientData = { id, slug, content };
  //get all results
  return ingredientData;
};
