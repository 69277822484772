import { CATEGORIES } from "../utils/blogUtils";
import { LANGUAGE } from "../utils/languageUtils";
import { hasData, toElement } from "../utils/utils";
import { BLOG } from "./utils.api";

export const getAllContent = (content) => {
  return toElement(content.rendered)
    .filter((el) => el.$$typeof === Symbol.for("react.element"))
    .filter(({ props }) => props.children);
};

export const getImagefromContent = (content) => {
  return getAllContent(content)
    .map(({ props }) => props.children)
    .filter(({ type }) => type === "img")
    .map(({ props }) => props.src);
};

export const getTextfromContent = (content) => {
  return getAllContent(content)
    .filter(({ type }) => type === "p")
    .map(({ props }) => props.children);
};

const getFormattedContentByKey = (content, key) => {
  return content
    .filter((c) => typeof c === "string" && c?.startsWith(key))
    .map((c) => c?.replace(`${key}::`, ""));
};

export const getContentByKey = (post, key) => {
  const textContent = getTextfromContent(post.content);
  let formattedContent = getFormattedContentByKey(textContent, key);

  if (key.startsWith("TEXT") && !hasData(formattedContent)) {
    const textBackup = getFormattedContentByKey(textContent, "TEXT_VN");
    formattedContent = textBackup;
  }

  return formattedContent;
};

export const getMultiLanguageProp = (prop, language) =>
  prop[language] || prop.vn;

export const getMultiLanguageContent = (posts, language) => {
  const text = posts.map((post) =>
    getContentByKey(post, `TEXT_${language.toUpperCase()}`)
  );
  return text;
};

export const getCategory = (category, language) => {
  if (!category) return;
  return category.label[language] || category.label.vn;
};

export const getMultilanguageCategories = (categories, language) => {
  if (!categories) return;

  const matchingCategories = categories
    .filter((category) => category && category !== BLOG)
    .map((category) => CATEGORIES.find(({ id }) => id === category));

  const matchingCategoriesName = matchingCategories.map((category) =>
    getCategory(category, language)
  );

  return matchingCategoriesName;
};

export const getMultiLanguagePostIds = (post) => {
  let postIDs = {};
  const languages = Object.keys(LANGUAGE);
  languages.forEach((language) => {
    postIDs[language] = post[language].id;
  });
  return postIDs;
};
