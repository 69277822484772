import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  ERROR_MESSAGE,
  IMAGES,
  getDefaultLanguage,
  hasData,
  imageWithDefault,
  saveToStorage,
  setDefaultCategories,
  setLanguageText,
} from "../utils/utils";
import * as HomeUtils from "../utils/homeUtils";
import * as NavbarUtils from "../utils/navbarUtils";
import * as FooterUtils from "../utils/footerUtils";
import * as SessionUtils from "../utils/sessionUtils";
import * as AboutUtils from "../utils/aboutUtils";
import * as IngredientUtils from "../utils/ingredientUtils";
import * as SpaceUtils from "../utils/spaceUtils";
import * as BlogUtils from "../utils/blogUtils";
import * as ContactUtils from "../utils/contactUtils";

import {
  fetchAboutNumber,
  fetchContact,
  fetchHomeBG,
  fetchIngredientParallax,
} from "./reduxThunk";
import {
  fetchIngredientDetails,
  fetchIngredientPreview,
  fetchIngredientLocations,
} from "./ingredient/ingredient.reduxThunk";
import { fetchPressReviews } from "./review/review.reduxThunk";
import { fetchBlogs, fetchBlogDetails } from "./blog/blog.reduxThunk";
import { fetchHistoryArticles } from "./history/history.reduxThunk";
import { fetchSpaceImages, fetchSpaceText } from "./space/space.reduxThunk";
import {
  getContentByKey,
  getImagefromContent,
  getMultiLanguageContent,
  getMultiLanguageProp,
} from "./utils.reducer";

const initialState = {
  navbarMode: "light",
  navbarText: setLanguageText(NavbarUtils),
  loading: false,
  homeBackground: { desktop: IMAGES.DEFAULT, mobile: IMAGES.DEFAULT },
  foodImages: undefined,
  ingredientParallaxImages: HomeUtils.INGREDIENT_PARALLAX,
  pressReviews: undefined,
  history: undefined,
  aboutNumber: undefined,
  blogs: undefined,
  blogCategories: setDefaultCategories(),
  blogDetails: undefined,
  activeLocation: undefined,
  ingredientLocations: undefined,
  ingredientChosenLocation: undefined,
  ingredientPreview: [],
  ingredientDetails: undefined,
  spaceImages: { slider: undefined, parallax: SpaceUtils.SPACE_PARALLAX },
  spaceContent: { heading: "", subText: "" },
  language: getDefaultLanguage(),
  homeText: setLanguageText(HomeUtils),
  footerText: setLanguageText(FooterUtils),
  addressText: setLanguageText(SessionUtils),
  aboutText: setLanguageText(AboutUtils),
  ingredientText: setLanguageText(IngredientUtils),
  spaceText: setLanguageText(SpaceUtils),
  blogText: setLanguageText(BlogUtils),
  contactText: undefined,
  errorMessage: ERROR_MESSAGE.DEFAULT,
  scrollTrigger: Array(5).fill(false),
};

export const xoicomSlide = createSlice({
  name: "xoicom",
  initialState,
  reducers: {
    setNavBarMode: (state, action) => {
      state.navbarMode = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
      const languageKey = `TEXT_${state.language.toUpperCase()}`;

      const elements = {
        navbar: NavbarUtils,
        footer: FooterUtils,
        home: HomeUtils, // home page
        about: AboutUtils, // about page
        ingredient: IngredientUtils, // ingredient page
        space: SpaceUtils, // space page
        blog: BlogUtils, //blog page
        address: SessionUtils,
      };

      Object.keys(elements).forEach((itemKey) => {
        state[`${itemKey}Text`] =
          elements[itemKey][languageKey] || elements[itemKey].TEXT_VN;
      });

      state.foodImages = HomeUtils.FOOD_IMAGES.map((food) => ({
        ...food,
        label: getMultiLanguageProp(food.label, state.language),
        heading: getMultiLanguageProp(food.heading, state.language),
        description: getMultiLanguageProp(food.description, state.language),
      }));

      //save to local storage
      saveToStorage("LANGUAGE", action.payload);
    },
    setScrollTrigger: (state, action) => {
      if (action.payload < state.scrollTrigger.length - 1) {
        state.scrollTrigger.splice(action.payload, 1, true);
      }
    },
    setChosenLocationEmpty: (state) => {
      state.ingredientChosenLocation = undefined;
    },
    setActiveLocation: (state, action) => {
      state.activeLocation = action.payload;
    },
    setActiveLocationEmpty: (state) => {
      state.activeLocation = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeBG.fulfilled, (state, action) => {
        const post = action.payload;

        if (hasData(post)) {
          const [first, second] = getImagefromContent(post.content);

          state.homeBackground = {
            desktop: imageWithDefault(first),
            mobile: imageWithDefault(second || first),
          };
        }
        state.loading = false;
      })
      .addCase(fetchIngredientParallax.fulfilled, (state, action) => {
        const post = action.payload;

        if (hasData(post)) {
          //fetch all content, use only the images url
          const imageURL = getImagefromContent(post.content);
          const ingredientImages = state.ingredientParallaxImages.map(
            (parallax, index) =>
              HomeUtils.getIngredientParallaxImage(parallax, imageURL[index])
          );
          state.ingredientParallaxImages = ingredientImages;
        }
        state.loading = false;
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        const blogs = action.payload;
        const language = state.language;

        if (hasData(blogs)) {
          const previewImages = BlogUtils.getPreviewImages(blogs, language);
          const blogArticles = blogs.map((blog, index) =>
            BlogUtils.getFormattedBlog(blog, language, previewImages[index])
          );

          state.blogs = blogArticles;

          // categories
          const categories = BlogUtils.getFormattedCategories(blogs, language);
          state.blogCategories = categories;
        }
        state.loading = false;
      })
      .addCase(fetchBlogDetails.fulfilled, (state, action) => {
        const blog = action.payload;

        if (hasData(blog)) {
          state.blogDetails = BlogUtils.getFormattedDetails(
            blog,
            state.language
          );
        }
        state.loading = false;
      })
      .addCase(fetchIngredientLocations.fulfilled, (state, action) => {
        const locations = action.payload;
        if (hasData(locations)) {
          const newLocations = IngredientUtils.getFormattedLocation(locations);
          state.ingredientLocations = newLocations;
        }
        state.loading = false;
      })
      .addCase(fetchIngredientPreview.fulfilled, (state, action) => {
        const ingredientPosts = action.payload;

        if (hasData(ingredientPosts) && ingredientPosts.data?.status !== 404) {
          const preview = IngredientUtils.getPreviewImages(ingredientPosts);
          const ingredients = ingredientPosts.map((ingredient, index) =>
            IngredientUtils.getFormattedIngredient(ingredient, preview[index])
          );

          saveToStorage("INGREDIENT", ingredients);
          state.ingredientChosenLocation = ingredientPosts[0].location;
          state.ingredientPreview = ingredients;
        }
        state.loading = false;
      })
      .addCase(fetchIngredientDetails.fulfilled, (state, action) => {
        const ingredient = action.payload;
        if (hasData(ingredient) && ingredient.data?.status !== 404) {
          const ingredientDetails =
            IngredientUtils.getFormattedDetails(ingredient);
          if (ingredientDetails) state.ingredientDetails = ingredientDetails;
        }
        state.loading = false;
      })
      .addCase(fetchPressReviews.fulfilled, (state, action) => {
        const reviews = action.payload;

        if (hasData(reviews)) {
          const description = getMultiLanguageContent(reviews, state.language);
          const formattedReviews = reviews.map((review, index) =>
            HomeUtils.getFormattedReview(review, description[index])
          );

          state.pressReviews = formattedReviews.filter(
            ({ description }) => description
          );
        }
        state.loading = false;
      })
      .addCase(fetchHistoryArticles.fulfilled, (state, action) => {
        const histories = action.payload;

        if (hasData(histories)) {
          const images = histories.map(
            (article) => getImagefromContent(article.content)[0] // get the first image
          );
          const historyContents = getMultiLanguageContent(
            histories,
            state.language
          ).map(([heading, description]) => ({ heading, description }));

          const formattedHistories = histories.map((history, index) =>
            AboutUtils.getFormattedHistory(
              history,
              images[index],
              historyContents[index]
            )
          );

          state.history = formattedHistories.filter(
            ({ heading, image }) => heading && image
          );
          //only get the post with both heading and image
        }
        state.loading = false;
      })
      .addCase(fetchSpaceImages.fulfilled, (state, action) => {
        const { images, parallax } = action.payload;
        const spaceImages = state.spaceImages;

        if (hasData(images)) {
          //images are in the same order as specified in the content pages
          //if there are more than 10 images, only use the first 10 images
          const sliderURL = getImagefromContent(images.content).slice(0, 9);
          const sliderImages = sliderURL.map((image, id) =>
            SpaceUtils.getSliderImage(image, id)
          );
          spaceImages.slider = sliderImages;
        }
        if (hasData(parallax)) {
          const parallaxURL = getImagefromContent(parallax.content);
          const parallaxImages = spaceImages.parallax.map((parallax, index) =>
            SpaceUtils.getParallaxImage(parallax, parallaxURL[index])
          );
          spaceImages.parallax = parallaxImages;
        }

        state.loading = false;
      })
      .addCase(fetchSpaceText.fulfilled, (state, action) => {
        const post = action.payload;

        if (hasData(post)) {
          const language = `TEXT_${state.language.toUpperCase()}`;
          const [heading, subText] = getContentByKey(post, language);

          state.spaceContent = { heading, subText };
        }
        state.loading = false;
      })
      .addCase(fetchAboutNumber.fulfilled, (state, action) => {
        const NUMBERS = AboutUtils.NUMBER_CARDS;
        const post = action.payload;
        const language = state.language;

        if (hasData(post)) {
          //get the first paragraph as VN version
          const about = {
            vn: getContentByKey(post, "TEXT_VN"),
            en: getContentByKey(post, "TEXT_EN"),
            number: getContentByKey(post, "NUMBER"),
          };

          const numbers = NUMBERS.map(({ number, description }, index) => ({
            number: about.number[index] || number,
            description: about[language][index] || description[language],
          }));

          state.aboutNumber = numbers;
        } else {
          state.aboutNumber = NUMBERS.map(({ number, description }) => ({
            number,
            description: description[language],
          }));
        }
        state.loading = false;
      })
      .addCase(fetchContact.fulfilled, (state, action) => {
        const contact = action.payload;

        if (hasData(contact)) {
          const contactData = ContactUtils.getFormattedContact(
            contact,
            state.language
          );

          state.contactText = contactData;
        }
        state.loading = false;
      })
      .addMatcher(
        isAnyOf(
          fetchHomeBG.pending,
          fetchIngredientParallax.pending,
          fetchBlogs.pending,
          fetchBlogDetails.pending,
          fetchIngredientLocations.pending,
          fetchIngredientPreview.pending,
          fetchIngredientDetails.pending,
          fetchPressReviews.pending,
          fetchHistoryArticles.pending,
          fetchSpaceImages.pending,
          fetchSpaceText.pending,
          fetchAboutNumber.pending,
          fetchContact.pending
        ),
        (state) => {
          state.loading = true;
        }
      );
  },
});

// Action creators are generated for each case reducer function
export const {
  setNavBarMode,
  setLanguage,
  setScrollTrigger,
  setChosenLocationEmpty,
  setActiveLocation,
  setActiveLocationEmpty,
} = xoicomSlide.actions;

export default xoicomSlide.reducer;
