export const TEXT_VN = {
  default: {
    visitUs: "Cơm đã xới, chờ bạn tới",
    address: "Số 36, Láng Hạ, Đống Đa, Hà Nội",
    showMap: "Xem bản đồ",
    hideMap: "Ẩn bản đồ",
  },
  cta: {
    heading: "Cơm đã xới, chờ bạn tới!",
    contactUs: "Liên hệ đặt bàn tại:",
  },
};

export const TEXT_EN = {
  default: {
    visitUs: "Visit us",
    address: "No.36, Lang Ha Street, Dong Da Distric, Hanoi",
    showMap: "View map",
    hideMap: "Hide map",
  },
  cta: {
    heading: "Visit us",
    contactUs: "Reservation hotline:",
  },
};
