import React from "react";
import "../../style/Footer.css";
import { useNavigate } from "react-router-dom";
import { FOOTER_CARDS } from "../../utils/footerUtils";

const cards = FOOTER_CARDS;

function ContactFooter({ language }) {
  const navigate = useNavigate();
  const handleClick = (card) => {
    if (card.id === 2) {
      //space page
      navigate("/space");
    }
  };

  const isSpacePage = window.location.pathname.includes("/space");

  return (
    <div
      className={`contact-footer-container ${
        isSpacePage ? "contact-footer-mb" : ""
      }`}
    >
      {cards.map((card) => (
        <ContactCard
          key={card.id}
          card={card}
          onClick={() => handleClick(card)}
          language={language}
        />
      ))}
    </div>
  );
}

function ContactCard({ card, onClick, language }) {
  const { id, link, alt, image, text } = card;
  return (
    <a key={id} className="footer-card" href={link}>
      <div className="footer-card clickable" onClick={onClick}>
        <img className="footer-image" alt={alt} src={image} />
        <div className="footer-card-text body-b1 white">{text[language]}</div>
      </div>
    </a>
  );
}

export default ContactFooter;
