import React from "react";
import Skeleton from "@mui/material/Skeleton";

function BlogCardSkeleton({ overflow }) {
  return (
    <div className="blog-card" style={{ width: "100%" }}>
      <div className="blog-card-image-container" style={{ width: "100%" }}>
        <Skeleton
          variant="rounded"
          width={overflow ? "32vw" : ""}
          height="100%"
        />
      </div>
      <div className="blog-card-text">
        <Skeleton variant="text" width="100%" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width="100%" sx={{ fontSize: "1rem" }} />
      </div>
    </div>
  );
}

export default BlogCardSkeleton;
