import parse from "html-react-parser";
import { LANGUAGE } from "./languageUtils";
import * as ReactDOMServer from "react-dom/server";
import { CATEGORIES } from "./blogUtils";

export const formatDate = (date) => {
  const formattedDate = new Date(date);
  return `${formattedDate.getDate()}/${
    formattedDate.getMonth() + 1
  }/${formattedDate.getFullYear()}`;
};

export const formatDateMonthYear = (date) => {
  const formattedDate = new Date(date);
  return `${formattedDate.getMonth() + 1}/${formattedDate.getFullYear()}`;
};

export const toElement = (renderedString) => {
  return parse(renderedString);
};

export const elementToString = (element) => {
  return ReactDOMServer.renderToString(element);
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const getDefaultLanguage = () => {
  const languageKey = localStorage.getItem("LANGUAGE");
  if (languageKey) {
    return LANGUAGE[languageKey].value;
  } else return LANGUAGE.vn.value;
};

export const setLanguageText = (content) => {
  let languageKey = "VN";

  const language = sessionStorage.getItem("LANGUAGE");
  const availableLanguages = Object.keys(LANGUAGE);

  if (availableLanguages.includes(language)) {
    languageKey = language.toUpperCase();
  }

  const text = content[`TEXT_${languageKey}`];
  return text;
};

export const setDefaultCategories = () => {
  const language = sessionStorage.getItem("LANGUAGE");

  const ALL = CATEGORIES[0];
  const category = { ...ALL, label: ALL.label[language] };
  return [category];
};

export const saveToStorage = (key, data) => {
  if (key === "LANGUAGE") {
    localStorage.setItem(key, data);
  } else {
    const id = data.map((data) => ({
      location: data.location,
      postID: data.postID,
    }));
    sessionStorage.setItem(key, JSON.stringify(id));
  }
};

export const getLanguageVersionFromStorage = (key, id, language) => {
  const postIDs = sessionStorage.getItem(key);

  if (!postIDs) return id;

  const languagePostId = JSON.parse(postIDs).find((data) =>
    Object.values(data.postID).includes(id)
  ).postID[language];

  return languagePostId || id;
};

export const checkOthers = (key, id) => {
  const data = sessionStorage.getItem(key);
  if (data) {
    const othersDataJSON = JSON.parse(data).filter(
      (data) => !Object.values(data.postID).includes(id)
    );
    return hasData(othersDataJSON);
  }
};

export const responsiveBlogPreview = (blogs, { medium, large }) => {
  let blogPreviews = blogs;
  if (blogs.length > 4) {
    if (medium && !large) {
      blogPreviews = blogs.slice(0, 3);
    } else {
      blogPreviews = blogs.slice(0, 4);
    }
  } else {
    if (medium && !large) {
      blogPreviews = blogs.slice(0, 3);
    } else {
      blogPreviews = blogs;
    }
  }
  return blogPreviews;
};

export const imageWithDefault = (image, mode) => {
  if (!mode) {
    return hasData(image) ? image : IMAGES.DEFAULT;
  } else {
    if (mode === "logo") {
      return hasData(image) ? image : IMAGES.DEFAULT_MICHELIN;
    }
  }
};

export const hasData = (data) => {
  return data && data?.length !== 0;
};

export const removePrefix = (str, prefix) => {
  const prefixStr = `${prefix}-`;
  if (prefix && str.startsWith(prefixStr)) {
    return str.slice(prefixStr.length);
  }
  return str;
};

export const removeSuffix = (string, suffix) => {
  if (string.endsWith(suffix)) {
    return string.slice(0, -suffix.length);
  }
  return string;
};

export const toNumber = (string) => {
  //check if string has %
  if (string.includes("%")) {
    //Check is string has number -> return string with %
    const newString = removeSuffix(string, "%");
    if (!isNaN(newString)) {
      return string;
    } else {
      return false;
    }
  } else if (!isNaN(string)) {
    return Number(string);
  } else {
    return false;
  }
};

export const removeDup = (arr) => {
  const uniqueArr = [...new Set(arr.map((item) => JSON.stringify(item)))].map(
    (item) => JSON.parse(item)
  );
  return uniqueArr;
};

export const LINK = {
  FACEBOOK: "https://www.facebook.com/tiemanxoicom/",
  INSTAGRAM: "https://www.instagram.com/xoicom.tieman/",
  TRIP_ADVISOR:
    "https://www.tripadvisor.com/Restaurant_Review-g293924-d21333681-Reviews-Xoi_Com_Tiem_an_gia_dinh-Hanoi.html",
  EMAIL: "xoicom.vn@gmail.com",
  MENU: "https://qr.flit.to/places/1333?fbclid=IwAR2ViM7VEtQCvEkzyaHuNCl6ZhTBlpy98neeQi8-vWQJAwAFrl42GkLJfow",
  MAP: "https://maps.app.goo.gl/xqA3gHvCAKt83iVB6",
  MGC_INSTA: "https://www.instagram.com/mgc.clb/",
};

export const EMAIL_URL =
  "https://public.herotofu.com/v1/c4e70bf0-c281-11ee-b355-cff7da8a1e7a";

export const ERROR_MESSAGE = {
  DEFAULT: "Problem fetching content",
  BACKGROUND: "Cannot fetch home landing image",
  BLOG_PREVIEW: "Cannot fetch blog preview",
  BLOG_DETAILS: "Cannot fetch blog details",
  HISTORY: "Cannot fetch history articles",
  REVIEW: "Cannot fetch press review",
  INGREDIENT_IMAGES: "Cannot fetch ingredient images",
  INGREDIENT_LOCATIONS: "Cannot fetch ingredient locations",
  INGREDIENT_DETAILS: "Cannot fetch ingredient details",
};

export const ERROR_ENG_MESSAGE = {
  DEFAULT: "Problem fetching content",
  BLOG_PREVIEW: "Cannot fetch English blog preview",
  BLOG_DETAILS: "Cannot fetch English blog details",
  HISTORY: "Cannot fetch English history articles",
  REVIEW: "Cannot fetch English press review",
  INGREDIENT_DETAILS: "Cannot fetch English ingredient details",
};

export const IMAGES = {
  RED_BG: "/assets/General/RedBackground.png",
  HAND_CHOPSTICK: "/assets/Home/hand-holding-chopstick.png",
  ABOUT_LANDING: "/assets/About/Landing.jpg",
  INGREDIENT_LANDING: "/assets/Ingredient/Landing.jpg",
  SPACE_LANDING: "/assets/Space/Landing.jpg",
  BLOG_LANDING: "",
  MEMORY_IMG: "/assets/About/Memory.png",
  LETTER_IMG: "/assets/About/Letter.png",
  LETTER2_IMG: "/assets/About/Letter2.png",
  GREEN_BG: "/assets/About/Green.png",
  ADDRESS_HOUSE_IMG: "/assets/Session/house.png",
  RESERVATION_BG: "/assets/Session/ReservationHome.png",
  DEFAULT: "/assets/Home/HomeLandingDefault.png",
  DEFAULT_VERTICAL: "/assets/Default/DefaultVertical.png",
  DEFAULT_MICHELIN: "/assets/Default/Michelin.jpg",
};

export const GRAPHICS = {
  HEADER_LOGO_WHITE: "/assets/About/LogoWhite.png",
  FOOTER_LOGO: "/assets/General/FooterLogo.png",
  ARROW_BUTTON: "/assets/General/RedArrow.svg",
};
