import React from "react";

const getTextClass = (index, mode, bigHeader) => {
  let className = "header-text";
  if (mode === "h2") {
    if (index === 1) {
      className += "-accent";
    } else return "";
  } else {
    if (index === 1) {
      if (bigHeader) className += " header-text-accent";
      else className = "accent-heading-text";
    } else {
      if (!bigHeader) className = "regular-heading-text";
    }
  }
  return className;
};

function XoiComHeading({ heading, textColor, bigHeader, mode }) {
  const text = heading.split("::").map((t, index) => (
    <span key={index} className={getTextClass(index, mode, bigHeader)}>
      {t}{" "}
    </span>
  ));

  return (
    <div style={{ color: textColor ? textColor : "black" }}>
      {mode === "h2" ? <h2>{text}</h2> : <div>{text}</div>}
    </div>
  );
}

export default XoiComHeading;
