import {
  getImagefromContent,
  getMultiLanguagePostIds,
  getTextfromContent,
} from "../api/utils.reducer";
import { LANGUAGE } from "./languageUtils";
import { hasData, imageWithDefault } from "./utils";
import mapJson from "../assets/Map/vn.json";

export const TEXT_VN = {
  landingText: "Nguyên liệu::tươi ngon::mỗi ngày",
  mapText: {
    heading:
      "Tìm kiếm và kết hợp cùng các nhà cung cấp thực phẩm sạch khắp mọi miền tổ quốc",
    bodyText:
      "<div>Xới Cơm đặc biệt chú trọng vào chất lượng và nguồn gốc của các nguyên liệu sử dụng hàng ngày. Bởi vậy, từng loại nguyên liệu của Bếp Xới sử dụng đều trải qua quá trình tuyển chọn kỹ lưỡng.<br />Dựa vào thổ nhưỡng, khí hậu của từng vùng đất và kỹ thuật chăm sóc của từng nông hộ mà các loại nguyên liệu đạt đến chất lượng tốt nhất. Đó là lý do Xới Cơm chọn măng trúc Yên Tử, su su Mù Cang Chải, hay thịt bò Ba Vì,...</div>",
    accentText: "Khám phá những nguyên liệu sạch Xới Cơm lựa chọn",
  },
  buttonText: {
    back: "Quay lại",
    listing: "Câu chuyện nguyên liệu",
  },
};

export const TEXT_EN = {
  landingText: "::Freshness::delivered everyday!",
  mapText: {
    heading:
      "Sourcing and partnering with local fresh food suppliers nationwide",
    bodyText:
      "<div>Xới Cơm places special emphasis on the quality and origin of the ingredients used daily. Therefore, each ingredient used by our kitchen undergoes a rigorous selection process.<br />Based on the soil, climate of each region, and the farming techniques of each household, the ingredients achieve the best quality. This is why Xới Cơm selects Yen Tu bamboo shoots, Mu Cang Chai chayote, or Ba Vi beef, among others.</div>",
    accentText: "Discover the story of our fresh ingredients",
  },
  buttonText: {
    back: "Back",
    listing: "Stories Hidden Within Our Ingredients",
  },
};

const getIngredientHeading = (postPair, language) => {
  return getTextfromContent(postPair[language].content)[0];
};

const getMultiLanguageIngredientHeadings = (post) => {
  let headings = {};
  const languages = Object.keys(LANGUAGE);
  languages.forEach((language) => {
    headings[language] = getIngredientHeading(post, language);
  });
  return headings;
};

export const getPreviewImages = (posts) => {
  return posts.map((post) => getImagefromContent(post.vn.content)[0]);
};

export const getFormattedIngredient = (post, preview) => {
  const { id, ingredient, location, vn } = post;
  return {
    id,
    ingredient,
    location,
    title: getMultiLanguageIngredientHeadings(post),
    image: vn.jetpack_featured_media_url,
    preview,
    postID: getMultiLanguagePostIds(post),
  };
};

const getMatchingLocationFromJson = (data) => {
  return mapJson.find((location) => location.name === data);
};

export const getFormattedLocation = (rawLocations) => {
  const newLocations = rawLocations
    .map((location) => getMatchingLocationFromJson(location))
    .filter((location) => location) //only get the one with the result
    .map((city) => {
      const { name, display_name, longitude, latitude } = city;
      return { name, display_name, coordinates: [longitude, latitude] };
    });

  return newLocations;
};

export const getFormattedDetails = (ingredient) => {
  const imageList = getImagefromContent(ingredient.content);
  const text = getTextfromContent(ingredient.content);

  if (!hasData(text.slice(1))) return;

  return {
    ...ingredient,
    title: text[0],
    images: hasData(imageList) ? imageList : [imageWithDefault()], //if there are no image show one default image
    content: text.slice(1),
  };
};

// map methods
export const getScale = (windowWidth, mobile, medium, large, xlarge) => {
  const scales = {
    mobile: (4000 * 1440) / windowWidth,
    small: (3200 * 1440) / windowWidth,
    medium: (4500 * 1440) / windowWidth,
    large: (4500 * 1440) / windowWidth,
    xlarge: (4500 * 1440) / windowWidth,
  };

  const scale = mobile
    ? scales.mobile
    : xlarge
    ? scales.xlarge
    : large
    ? scales.large
    : medium
    ? scales.medium
    : scales.small;

  return scale;
};

export const getCenter = (mobile, medium, large, xlarge) => {
  const centers = {
    mobile: [106, 17],
    small: [105, 16],
    medium: [106, 16],
    large: [105, 16],
    xlarge: [105, 16],
  };

  const center = mobile
    ? centers.mobile
    : xlarge
    ? centers.xlarge
    : large
    ? centers.large
    : medium
    ? centers.medium
    : centers.small;

  return center;
};
