import React from "react";
import { motion } from "framer-motion";
import { slideUp } from "../../utils/animationUtils";

function NatureImage({ imageSrc, onClick, className }) {
  return (
    <motion.img
      className={`clickable ${className}`}
      src={imageSrc}
      alt=""
      variants={slideUp}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
      onClick={onClick}
    />
  );
}

export default NatureImage;
