import React from "react";
import Box from "@mui/material/Box";

function SpiceContent({ item, animation, description }) {
  const { image, imageSrc } = item;
  const animationClass = animation ? "fade-in" : "fade-out";
  const spiceImageClass = image === "salt" ? "bottom" : "center";
  return (
    <div className={`content spice-content ${animationClass}`}>
      <div className="spice-image-container">
        <img
          className={`spice-details-image zoom spice-image-${spiceImageClass}`}
          src={imageSrc}
          alt={image}
          loading="lazy"
        />
      </div>
      <div className="spice-details-text">
        <Box className="body-b1">{description}</Box>
      </div>
    </div>
  );
}

export default SpiceContent;
