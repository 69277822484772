export const breakpoint = {
  mobile: "(max-width: 600px)",
  small: "(min-width: 600px)",
  mobileSm: "(max-width: 768px)",
  medium: "(min-width: 768px)",
  large: "(min-width: 992px)",
  xlarge: "(min-width: 1200px)",
};

export const orientation = {
  landscape: "(orientation: landscape)",
  portrait: "(orientation: portrait)",
};
