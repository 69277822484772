import React, { useEffect, useState } from "react";
import XoiComHeading from "../generics/XoiComHeading";
import Grid from "@mui/material/Unstable_Grid2";
import { motion } from "framer-motion";
import { PROCESS_CARDS } from "../../utils/aboutUtils";
import LazyImage from "../generics/LazyImage";
import { staggerUp } from "../../utils/animationUtils";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../../utils/responsiveUtils";
const getLanguageText = (cards, language) => {
  return cards.map((step) => step.description[language]);
};
function ProcessSession({ language, heading }) {
  const mobile = useMediaQuery(breakpoint.mobile);

  const processCards = PROCESS_CARDS;
  const [processDescription, setProcessDescription] = useState(
    getLanguageText(processCards, language)
  );

  useEffect(() => {
    setProcessDescription(getLanguageText(processCards, language));
  }, [language]);

  return (
    <div className="about-process-session">
      <div style={{ maxWidth: language === "en" && mobile ? 260 : 500 }}>
        <XoiComHeading heading={heading} />
      </div>
      <div style={{ margin: mobile ? 0 : "0 80px" }}>
        <Grid
          container
          spacing={2}
          columns={{ xs: 2, sm: 3, md: 5 }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {processCards.map((card, index) => (
            <Grid item="true" xs={1} key={card.title}>
              <motion.div
                variants={staggerUp}
                initial="initial"
                whileInView="animate"
                viewport={{ once: true }}
                custom={index}
                className="about-process-card-inner-container"
              >
                <LazyImage
                  className="about-process-card-image"
                  imageSrc={card.imageSrc}
                  alt={card.title}
                />
                <div className="about-process-card-text">
                  {processDescription[index]}
                </div>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default ProcessSession;
