import React from "react";
import Box from "@mui/material/Box";
import "../../style/Slide.css";
import "../../style/components/HistorySlide.css";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import LoadingBackdrop from "../skeleton/LoadingBackdrop";

function HistoryDetails({ steps, activeStep, handleStep, animation }) {
  const loading = useSelector((state) => state.reducer.loading);
  const aboutText = useSelector((state) => state.reducer.aboutText);
  const animationClass = animation ? "fade-in" : "fade-out";

  return (
    <Box style={{ display: "flex" }}>
      <LoadingBackdrop open={loading} />
      <Box className="history-text-button-container">
        {/* content */}
        <motion.div className="history-content">
          <div className="history-image-container">
            {steps.map(
              (step, index) =>
                index === activeStep && (
                  <HistoryImage
                    key={step.alt}
                    step={step}
                    animationClass={animationClass}
                  />
                )
            )}
          </div>
          <div className="history-details-text">
            <div className="history-container">
              <div className="cap-r15">{aboutText.journey}</div>
              <div className="history-text-container">
                {steps.map(
                  (step, index) =>
                    index === activeStep && (
                      <HistoryText
                        key={step.alt}
                        step={step}
                        animationClass={animationClass}
                      />
                    )
                )}
              </div>
            </div>
            <div className="history-timeline-wrapper">
              <div className="history-line"></div>
              <div className="history-button-container">
                {steps.map((step, index) => (
                  <HistoryButton
                    key={step.alt}
                    step={step}
                    active={index === activeStep}
                    start={index === 0}
                    onClick={handleStep(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </motion.div>
      </Box>
    </Box>
  );
}

function HistoryImage({ step, animationClass }) {
  const { alt, image } = step;
  return (
    <img className={`history-image ${animationClass}`} alt={alt} src={image} />
  );
}

function HistoryText({ step, animationClass }) {
  return (
    <div className={`history-text ${animationClass}`}>
      <h1>{step.heading}</h1>
      <Box className="body-b1">{step.description}</Box>
    </div>
  );
}

function HistoryButton({ step, active, start, onClick }) {
  const startButtonClass = start ? "history-button-start" : "";
  const historyDotClass = active ? "history-dot-active" : "history-dot-default";
  const buttonClass = `custom-button-slide-${active ? "active" : "default"}`;

  return (
    <div className={`history-button ${startButtonClass}`}>
      <div className={`history-dot ${historyDotClass}`}></div>
      <button
        onClick={onClick}
        className={`custom-button-slide ${buttonClass}`}
      >
        {step.date}
      </button>
    </div>
  );
}

export default HistoryDetails;
