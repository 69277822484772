import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const getNumberFromString = (inputString) => {
  const [numericString, remain] = inputString.split("_");
  let string = "";
  let number = Number(numericString);
  // for number with ,
  if (numericString.includes(",")) {
    string = `${numericString.split(",")[0]},`;
    number = numericString.split(",")[1];
  } else if (numericString.includes(".")) {
    string = `${numericString.split(".")[0]}.`;
    number = numericString.split(".")[1];
  }
  return { string, number, remain };
};

function Counter({ end, duration }) {
  const [startNumber, setStartNumber] = useState(0);
  const [startString, setStartString] = useState("");
  const [endNumber, setEndNumber] = useState(end);
  const [addedString, setAddedString] = useState();

  useEffect(() => {
    if (typeof end === "string") {
      const newString = getNumberFromString(end);
      setStartString(newString.string);
      setEndNumber(newString.number);
      setAddedString(newString.remain);
      if (newString.number > 100) {
        setStartNumber(newString.number - 100);
      }
    }
    if (endNumber > 100) {
      setStartNumber(endNumber - 100);
    }
  }, []);

  const countUp = (endNumber) => {
    if (startNumber < endNumber) {
      setTimeout(() => {
        setStartNumber(startNumber + 1);
      }, duration);
    } else {
      setStartNumber(startNumber);
    }
  };
  return (
    <motion.div
      className="about-green-number"
      whileInView={() => countUp(endNumber)}
    >
      {startString && <span>{startString}</span>}
      <span>{startNumber}</span>
      {addedString && <span>{addedString}</span>}
    </motion.div>
  );
}

export default Counter;
