import React, { useState } from "react";
import "../../style/Slide.css";
import "../../style/components/BlogSlide.css";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import Swipe from "./Swipe";

const RATIO = { VERTICAL: "vertical", HORIZONTAL: "horizontal" };

function ImageSwipe({
  steps,
  activeStep,
  handleStep,
  stepper,
  blogPreview,
  ratio,
  autoplay,
  handleClickTitle,
}) {
  const [inview, setInview] = useState(false);

  const content = steps.map((step, index) => (
    <div key={step.id || step.title} className="image-swipe-wrapper">
      {Math.abs(activeStep - index) <= 2 && (
        <ImageWithTitle
          step={step}
          blogPreview={blogPreview}
          ratio={ratio}
          onClick={() => handleClickTitle(step)}
          vertical={blogPreview || ratio === RATIO.VERTICAL}
        />
      )}
    </div>
  ));

  return (
    <motion.div
      className="swipe-container"
      whileInView={() => setInview(true)}
      viewport={{ once: true }}
    >
      <Swipe
        steps={steps}
        activeStep={activeStep}
        handleStep={handleStep}
        autoPlayTrigger={inview && autoplay}
        contentComponent={content}
      />
      <div className="blog-slide-dots-container-mobile">{stepper}</div>
    </motion.div>
  );
}

function ImageWithTitle({ step, blogPreview, vertical, onClick }) {
  const { previewImage, image, alt, title, heading } = step;
  const imageClass = `swipe-image${vertical ? "-vertical" : ""}`;
  const headingClass =
    "header-text blog-slide-heading blog-swipe-heading white clickable";

  return (
    <div>
      <Box
        className={imageClass}
        component="img"
        src={blogPreview ? previewImage : image}
        alt={alt || title}
      />
      {blogPreview && (
        <div className={headingClass} onClick={onClick}>
          {heading}
        </div>
      )}
    </div>
  );
}

export default ImageSwipe;
