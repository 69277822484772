import React, { useState } from "react";
import "../style/Session.css";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SVGIcon from "./generics/SVGIcon";
import Email from "./Email";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../utils/responsiveUtils";
import { motion } from "framer-motion";

const slideUp = {
  initial: { display: 0, y: "10px" },
  animate: { opacity: 1, y: 0, transition: "all 0.5s ease-in-out" },
};

const slideDown = {
  initial: { opacity: 1, y: 0 },
  animate: { opacity: 0, y: "10px", transition: "all 0.5s ease-in-out" },
};

function StickyCTA() {
  const mobile = useMediaQuery(breakpoint.mobile);
  const [openCTA, setOpenCTA] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [animation, setAnimation] = useState(slideUp);
  const [openEmail, setOpenEmail] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(false);

  const handleOpenCTA = () => {
    if (openCTA) {
      setAnimation(slideDown);
      setRotate(false);
      setTimeout(() => {
        setOpenCTA(false);
      }, 500);
    } else {
      setAnimation(slideUp);
      setRotate(true);
      setOpenCTA(true);
    }
  };

  const handleClickOpenEmail = () => {
    setOpenEmail(true);
    setSubmittedEmail(false);
  };

  const handleCloseEmail = () => {
    setOpenEmail(false);
    setSubmittedEmail(true);
  };

  return (
    <div className={mobile ? "mobile-sticky-container" : "sticky-container"}>
      {mobile && openCTA && (
        <motion.div
          variants={animation}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
        >
          <ButtonGroup
            handleClickOpenEmail={handleClickOpenEmail}
            mobile={mobile}
          />
        </motion.div>
      )}
      {mobile && (
        <div>
          <button
            className="custom-icon-button cta-toggle-button"
            onClick={handleOpenCTA}
          >
            <AddRoundedIcon
              fontSize="large"
              color="white"
              className={`rotate-button ${rotate ? "close" : "add"}-rotate`}
            />
          </button>
        </div>
      )}
      {!mobile && (
        <ButtonGroup
          handleClickOpenEmail={handleClickOpenEmail}
          size={mobile && "48"}
        />
      )}
      <Email
        open={openEmail}
        submitted={submittedEmail}
        setSubmitted={setSubmittedEmail}
        handleClose={handleCloseEmail}
      />
    </div>
  );
}

function ButtonGroup({ handleClickOpenEmail, size }) {
  return (
    <div className="cta-button-group">
      {["facebook", "instagram", "tripadvisor"].map((icon) => (
        <button key={`cta-${icon}`} className="custom-icon-button">
          <SVGIcon icon={`cta-${icon}`} size={size} />
        </button>
      ))}
      <button className="custom-icon-button" onClick={handleClickOpenEmail}>
        <SVGIcon icon="cta-email" size={size} />
      </button>
    </div>
  );
}
export default StickyCTA;
