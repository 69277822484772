import React from "react";
import BlogCard from "./BlogCard";
import Grid from "@mui/material/Unstable_Grid2";
import BlogCardSkeleton from "../skeleton/BlogCardSkeleton";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../../utils/responsiveUtils";

function BlogListFull({ blogs, loading }) {
  const mobile = useMediaQuery(breakpoint.mobile);
  const isBlogPage = window.location.pathname.includes("/blog");

  return (
    <div style={{ margin: mobile ? "20px 24px" : "80px 80px" }}>
      {loading ? (
        <Grid
          container
          spacing={2}
          columns={12}
          rowSpacing={6}
          style={{
            display: "inline-flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {[1, 2, 3, 4].map((index) => (
            <Grid key={index} xs={isBlogPage ? 6 : 12} sm={6} md={4} lg={3}>
              <BlogCardSkeleton />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2} columns={12} rowSpacing={6}>
          {blogs.map((blog) => (
            <Grid key={blog.id} xs={isBlogPage ? 6 : 12} sm={6} md={4} lg={3}>
              <BlogCard blog={blog} />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}

export default BlogListFull;
