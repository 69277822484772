import { getAllContent } from "../api/utils.reducer";
import { elementToString } from "./utils";

export const CONTACT_TABS = [
  {
    label: { vn: "Đặt bàn tại Xới Cơm", en: "Table Reservation at Xoi Com" },
    id: "booking",
  },
  {
    label: { vn: "Chính sách khách hàng", en: "Customer's Loyalty Program" },
    id: "policy",
  },
];

const getContactData = (data, category, language) => {
  const contactPost = data[category][language] || data[category].vn;

  return getAllContent(contactPost.content);
};

export const getFormattedContact = (contact, language) => {
  let newContactData = {};
  ["booking", "policy"].forEach((category) => {
    const contactData = getContactData(contact, category, language);
    newContactData[category] = elementToString(contactData);
  });

  return newContactData;
};
