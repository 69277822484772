import React, { useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import "../style/Reusable.css";
import { styled } from "@mui/material/styles";
import CloseButton from "./generics/CloseButton";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../utils/responsiveUtils";
import { useDispatch } from "react-redux";
import { sendEmail } from "../api/reduxThunk";
import Input from "./generics/Input";

const FIELDS = {
  name: "Tên của bạn",
  contact: "Số điện thoại hoặc email liên hệ",
  message: "Lời nhắn của bạn với Xới Cơm",
};

const toObject = (fieldsArray) => {
  const fields = fieldsArray.map((field) => ({
    name: field,
    value: "",
    touched: false,
  }));
  return fields;
};

const getData = (fieldsArray) => {
  const data = {};
  fieldsArray.forEach((field) => {
    data[field.name] = field.value;
  });

  return data;
};

function Email({ open, submitted, setSubmitted, handleClose }) {
  const dispatch = useDispatch();
  const mobile = useMediaQuery(breakpoint.mobile);
  const [userInfo, setUserInfo] = useState(toObject(Object.keys(FIELDS)));

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const newInfo = userInfo.map((info) =>
      info.name === name ? { ...info, value, touched: true } : info
    );

    setUserInfo(newInfo);
  };

  const handleTouched = () => {
    const newInfo = userInfo.map((info) => ({ ...info, touched: true }));
    setUserInfo(newInfo);
  };

  const handleReset = () => {
    const newInfo = userInfo.map((info) => ({
      ...info,
      value: "",
      touched: false,
    }));
    setUserInfo(newInfo);
  };

  const handleSubmit = () => {
    handleTouched();
    const allFieldsValid = userInfo.every((info) => info.value);
    if (allFieldsValid) {
      const emailData = getData(userInfo);
      send(emailData);
    }
  };

  const send = (data) => {
    setSubmitted(false);
    dispatch(sendEmail(data))
      .unwrap()
      .then(() => {
        setSubmitted(true);
        handleReset();
      });
  };

  return (
    <div>
      <StyledEmailDialog
        mobile={mobile}
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => event.preventDefault(),
        }}
      >
        <CloseButton
          onClick={handleClose}
          right={16}
          top={16}
          backgroundColor="transparent"
        />
        {submitted ? (
          <div>
            <h1 style={{ lineHeight: "20px" }}>Cảm ơn bạn!</h1>
            <div className="body-b2">
              Lời nhắn của bạn đã được gửi, đội ngũ Xới Cơm sẽ trả lời bạn trong
              thời gian sớm nhất.
            </div>
          </div>
        ) : (
          <div>
            <h1 style={{ lineHeight: "20px" }}>Chào bạn!</h1>
            <Box
              className="email-dialog-container"
              component="form"
              sx={{ "& .MuiTextField-root": { m: "8px 0" } }}
              noValidate
              autoComplete="off"
            >
              {userInfo.map((info) => (
                <Input
                  key={info.name}
                  name={info.name}
                  value={info.value}
                  error={!info.value && info.touched}
                  label={FIELDS[info.name]}
                  multiline={info.name === "message"}
                  rows={info.name === "message" && 4}
                  onChange={handleChange}
                />
              ))}
            </Box>
            <DialogActions>
              <button
                className="custom-button-link"
                style={{ color: "#FFF7EA" }}
                onClick={handleSubmit}
              >
                Gửi lời nhắn
              </button>
            </DialogActions>
          </div>
        )}
      </StyledEmailDialog>
    </div>
  );
}

const StyledEmailDialog = styled(Dialog)((p) => ({
  "& .MuiDialog-paper": {
    backgroundImage: "url(/assets/General/RedBackground2.png)",
    width: p.mobile ? "100%" : "50%",
    maxWidth: 512,
    padding: p.mobile ? "32px 16px" : 40,
    color: "#FFF7EA",
  },
  "& .MuiDialogActions-root": {
    marginTop: 16,
    justifyContent: "flex-start",
  },
}));

export default Email;
