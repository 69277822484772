import React from "react";
import SlideWrapper from "../generics/SlideWrapper";

function ImagePopup({ images, open, handleClosePopup, selected }) {
  return (
    <SlideWrapper
      steps={images}
      mode="image-popup"
      openPopup={open}
      handleClosePopup={handleClosePopup}
      selected={selected}
    />
  );
}

export default ImagePopup;
