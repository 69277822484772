import { MobileStepper } from "@mui/material";
import React from "react";

function StepperDots({ length, activeStep, color = "#FFF7EA" }) {
  const stepperStyle = {
    maxWidth: 400,
    backgroundColor: "transparent",
    "& .MuiMobileStepper-dots": { gap: "12px" },
    "& .MuiMobileStepper-dot": {
      backgroundColor: color,
      opacity: 0.5,
    },
    "& .MuiMobileStepper-dotActive": {
      "@keyframes width-increase": {
        "0%": { width: "10px" },
        "100%": { width: "28px" },
      },
      animation: "width-increase 0.2s ease",
      backgroundColor: color,
      opacity: 1,
      width: 28,
      borderRadius: 17,
    },
  };

  return (
    <MobileStepper
      steps={length}
      position="static"
      activeStep={activeStep}
      sx={stepperStyle}
    />
  );
}

export default StepperDots;
