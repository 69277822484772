import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "../../style/Slide.css";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../../utils/responsiveUtils";
import { slideUpFromZero } from "../../utils/animationUtils";
import XoiComHeading from "../generics/XoiComHeading";
import FoodImage from "./FoodImage";

function FoodSlide({
  steps,
  foodSteps,
  activeStep,
  backButton,
  nextButton,
  handleStep,
  animation,
}) {
  const homeText = useSelector((state) => state.reducer.homeText);
  const loading = useSelector((state) => state.reducer.foodSlideLoading);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showGraphic, setShowGraphic] = useState(false);
  const mobile = useMediaQuery(breakpoint.mobile);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  //handle show for full graphic
  const handleShow = () => {
    setTimeout(() => {
      setShowGraphic(true);
    }, 1000);
  };

  const containerHeight = mobile
    ? (236 / 390) * windowWidth
    : (586 / 1440) * windowWidth;

  const display = showGraphic ? "display-block" : "display-none";

  return (
    <Box id="foodSlide" className="slide-container">
      {/* image display */}
      {loading ? (
        <Skeleton variant="rounded">
          <div className="food-graphic-container"></div>
        </Skeleton>
      ) : (
        <motion.div
          className="food-graphic-container"
          style={{ height: containerHeight }}
          whileInView={handleShow}
        >
          <motion.div className={`food-container ${display}`}>
            {steps.map((step, index) => (
              <FoodImage
                key={step.title}
                step={step}
                index={index}
                active={index === activeStep}
                mobile={mobile}
                windowWidth={windowWidth}
              />
            ))}
          </motion.div>
          <motion.div className={`home-moi-com-heading-container`}>
            <motion.div
              className="home-moi-com-heading"
              variants={slideUpFromZero}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
            >
              {homeText.HOME_MOI_COM_HEADING}
            </motion.div>
          </motion.div>
        </motion.div>
      )}
      {/* text button controller */}
      <motion.div
        className={`text-button-container ${display}`}
        style={{ zIndex: 10 }}
      >
        {!mobile && backButton}
        {/* content */}
        <div>
          <div>
            {foodSteps.map(
              (step, index) =>
                index === activeStep && (
                  <FoodText
                    key={step.title}
                    step={step}
                    animationClass={animation ? "fade-in" : "fade-out"}
                  />
                )
            )}
          </div>
          <div className="stepper">
            {foodSteps.map((step, index) => (
              <FoodButton
                key={step.title}
                step={step}
                onClick={handleStep(index)}
                active={index === activeStep && animation}
              />
            ))}
          </div>
        </div>
        {!mobile && nextButton}
      </motion.div>
    </Box>
  );
}

function FoodText({ step, animationClass }) {
  const { heading, description } = step;

  return (
    <motion.div className={`body-b2 content-container ${animationClass}`}>
      <div className="content-heading-container">
        <XoiComHeading heading={heading} />
      </div>
      <Box className="content-text-container" style={{ fontWeight: 300 }}>
        {description}
      </Box>
    </motion.div>
  );
}
function FoodButton({ step, active, onClick }) {
  const buttonClass = `custom-button-slide-${active ? "active" : "default"}`;

  return (
    <button onClick={onClick} className={`custom-button-slide ${buttonClass}`}>
      {step.label}
    </button>
  );
}

export default FoodSlide;
