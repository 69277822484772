import React, { useState, useEffect } from "react";
import "../style/Contact.css";
import Booking from "../components/contact/Booking";
import styled from "styled-components";
import Policy from "../components/contact/Policy";
import { useDispatch, useSelector } from "react-redux";
import { setNavBarMode } from "../api/xoicom.reducer";
import { scrollToTop } from "../utils/utils";
import { fetchContact } from "../api/reduxThunk";
import LoadingBackdrop from "../components/skeleton/LoadingBackdrop";
import { CONTACT_TABS } from "../utils/contactUtils";
import { getMultiLanguageProp } from "../api/utils.reducer";

const Tab = styled.button`
  padding: 4px;
  cursor: pointer;
  color: grey;
  background: white;
  border: 0;
  outline: 0;
  border-bottom: 2px solid grey;
  ${({ active }) =>
    active === "true" &&
    `color: #9A1023;
    border-bottom: 2px solid #9A1023;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 24px;
`;
const types = CONTACT_TABS;

function Contact() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(types[0].id);

  const contactText = useSelector((state) => state.reducer.contactText);
  const loading = useSelector((state) => state.reducer.loading);
  const language = useSelector((state) => state.reducer.language);

  useEffect(() => {
    scrollToTop();
    dispatch(setNavBarMode("dark"));
  }, []);

  useEffect(() => {
    dispatch(fetchContact());
  }, [language]);

  return (
    <div>
      <LoadingBackdrop open={loading} />
      <div className="contact-container">
        <ButtonGroup>
          {types.map((type) => (
            <Tab
              key={type.id}
              active={(activeTab === type.id).toString()}
              onClick={() => setActiveTab(type.id)}
            >
              <h3 style={{ margin: 0 }}>
                {getMultiLanguageProp(type.label, language)}
              </h3>
            </Tab>
          ))}
        </ButtonGroup>
        {contactText && (
          <div>
            {activeTab === "booking" && <Booking text={contactText.booking} />}
            {activeTab === "policy" && <Policy text={contactText.policy} />}
          </div>
        )}
      </div>
    </div>
  );
}

export default Contact;
