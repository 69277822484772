import { HISTORY, fetchByCategory } from "../utils.api";

const LIMIT = 8;
//get history writing
export const historyApi = async () => {
  const historyData = await fetchByCategory(
    HISTORY,
    `per_page=${LIMIT}&orderby=date&&order=asc`
  );

  //get all results
  return historyData;
};
