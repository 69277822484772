import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchIngredientParallax } from "../../api/reduxThunk";
import { useNavigate } from "react-router-dom";
import "../../style/components/IngredientParallax.css";
import SlideWrapper from "../generics/SlideWrapper";
import IngredientImages from "./IngredientImages";

function IngredientParallax({ text }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const targetRef = useRef();
  const [openSlide, setOpenSlide] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const ingredientImages = useSelector(
    (state) => state.reducer.ingredientParallaxImages
  );
  const loading = useSelector((state) => state.reducer.loading);

  useEffect(() => {
    dispatch(fetchIngredientParallax());
  }, []);

  const handleOpenSlide = (index) => {
    setSelectedImage(index);
    setOpenSlide(true);
  };

  const handleCloseSlide = () => {
    setOpenSlide(false);
  };

  return (
    <div className="home-ingredient-session" ref={targetRef}>
      {!loading && (
        <div className="home-ingredient-wrapper">
          <IngredientImages
            target={targetRef}
            images={ingredientImages}
            handleOpenSlide={handleOpenSlide}
          />
          <SlideWrapper
            steps={ingredientImages}
            selected={selectedImage}
            mode="image-popup"
            openPopup={openSlide}
            handleClosePopup={handleCloseSlide}
          />
        </div>
      )}
      <div className="home-ingredient-text-container">
        <div className="home-ingredient-text">
          <h1 style={{ color: "white" }}>{text.heading}</h1>
          {/* <button
            className="custom-button-link"
            style={{ color: "white" }}
            onClick={() => {
              navigate("/ingredient");
            }}
          >
            {text.button}
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default IngredientParallax;
