import { createAsyncThunk } from "@reduxjs/toolkit";
import { blogApi, blogApiByID } from "./blog.wpapi";

export const fetchBlogs = createAsyncThunk("blogs/get", async () => {
  const response = await blogApi();
  return response;
});

export const fetchBlogDetails = createAsyncThunk(
  "blog-detail/get",
  async (id) => {
    const response = await blogApiByID(id);
    return response;
  }
);
