import React, { useEffect, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import "../../style/components/IngredientParallax.css";
import LazyImage from "../generics/LazyImage";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../../utils/responsiveUtils";

function IngredientImages({ target, images, handleOpenSlide }) {
  const mobile = useMediaQuery(breakpoint.mobileSm);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const originalWidth = mobile ? 390 : 1440;
  const imageMode = mobile ? "mobile" : "default";

  const { scrollYProgress } = useScroll({
    target,
    offset: ["start end", "end start"],
  });
  const layer1 = useTransform(scrollYProgress, [0, 1], [0, mobile ? 70 : 200]);
  const layer2 = useTransform(
    scrollYProgress,
    [0, 1],
    [0, mobile ? -70 : -100]
  );

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const position = (image) => {
    return {
      width: (image[imageMode].size.width / originalWidth) * windowWidth,
      left: (image[imageMode].position.left / originalWidth) * windowWidth,
      top: (image[imageMode].position.top / originalWidth) * windowWidth,
    };
  };

  return (
    <motion.div className="home-ingredient-image">
      {images.map((image, index) => (
        <motion.div
          key={image.title}
          className={`clickable ${index % 2 === 0 ? "layer01" : "layer02"}`}
          loading="lazy"
          style={{
            ...position(image),
            y: index % 2 === 0 ? layer1 : layer2,
          }}
          onClick={() => handleOpenSlide(index)}
        >
          <LazyImage imageSrc={image.image} alt={image.title} />
        </motion.div>
      ))}
    </motion.div>
  );
}

export default IngredientImages;
