import React, { useState, useRef } from "react";
import "../style/components/AddressSession.css";
import Collapse from "@mui/material/Collapse";
import { IMAGES } from "../utils/utils";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../utils/responsiveUtils";

const { ADDRESS_HOUSE_IMG } = IMAGES;

function AddressSession({ mode }) {
  const ref = useRef(null);
  const [showMap, setShowMap] = useState(false);
  const mobile = useMediaQuery(breakpoint.mobile);
  const addressText = useSelector((state) => state.reducer.addressText);
  const isSpacePage = window.location.pathname.includes("/space");

  const handleClick = () => {
    setShowMap(!showMap);
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      className={`address-session ${isSpacePage && "address-session-mobile"}`}
    >
      {mobile ? (
        <div className="address-container">
          <img className="house-image" src={ADDRESS_HOUSE_IMG} alt="address" />
          <AddressText
            text={addressText}
            onClick={handleClick}
            showMap={showMap}
          />
        </div>
      ) : (
        <div
          className={`${
            mode === "address" ? "" : "cta-container"
          } address-container`}
        >
          <img className="house-image" src={ADDRESS_HOUSE_IMG} alt="address" />
          {mode === "address" ? (
            <AddressText
              text={addressText}
              onClick={handleClick}
              showMap={showMap}
            />
          ) : (
            <CTAText text={addressText} />
          )}
        </div>
      )}
      <Collapse ref={ref} in={showMap} className="google-map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.4584662054153!2d105.81056247406363!3d21.01433398827629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab3d937ecfa1%3A0x73754896a44debd!2zWOG7m2kgQ8ahbSAtIFRp4buHbSDEg24gZ2lhIMSRw6xuaA!5e0!3m2!1svi!2s!4v1700406786839!5m2!1svi!2s"
          width="100%"
          height="450"
          allowFullScreen={true}
          loading="eager"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Collapse>
    </div>
  );
}

function AddressText({ text, onClick, showMap }) {
  const { visitUs, address, hideMap, showMap: showMapText } = text.default;
  return (
    <div className="address-text-container">
      <div className="cap-r15" style={{ color: "white" }}>
        {visitUs}
      </div>
      <div className="address-text">{address}</div>
      <button
        className="custom-button-link"
        style={{ color: "white" }}
        onClick={onClick}
      >
        {showMap ? hideMap : showMapText}
      </button>
    </div>
  );
}

function CTAText({ text }) {
  const { heading, contactUs } = text.cta;
  return (
    <div className="address-text-container">
      <h2 style={{ color: "white" }}>{heading}</h2>
      <div style={{ gap: 12 }}>
        <span className="cap-r15" style={{ color: "white", marginRight: 4 }}>
          {contactUs}
        </span>
        <span>
          <a
            href="tel:0866810736"
            className="custom-button-link"
            style={{
              color: "white",
              display: "inline",
              fontWeight: 300,
              padding: 0,
            }}
          >
            0866810736
          </a>
        </span>
      </div>
    </div>
  );
}

export default AddressSession;
