import React from "react";
import { Link } from "react-router-dom";
import { LINK } from "../../utils/utils";

function PageButton({ page, pageClass, color = "white" }) {
  const { name, displayName } = page;

  return name !== "menu" ? (
    <Link className={`custom-button-navbar ${pageClass}`} key={name} to={name}>
      {displayName}
    </Link>
  ) : (
    <button className={`custom-button-navbar ${pageClass}`}>
      <a className={color} href={LINK.MENU}>
        Menu
      </a>
    </button>
  );
}

export default PageButton;
