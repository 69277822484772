import React from "react";

function TextParagraph({
  content,
  style,
  body = "body-b2",
  headingClass = "",
  bodyClass = "",
}) {
  const { heading, bodyText } = content;
  return (
    <>
      <h3 className={headingClass}>{heading}</h3>
      <div className={`${body} ${bodyClass}`} style={style}>
        {bodyText}
      </div>
    </>
  );
}

export default TextParagraph;
