import React from "react";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { autoPlay } from "react-swipeable-views-utils-react-18-fix";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Swipe({ activeStep, handleStep, autoPlayTrigger, contentComponent }) {
  const theme = useTheme();
  return (
    <AutoPlaySwipeableViews
      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
      index={activeStep}
      onChangeIndex={handleStep}
      enableMouseEvents
      autoplay={autoPlayTrigger}
    >
      {contentComponent}
    </AutoPlaySwipeableViews>
  );
}

export default Swipe;
