import React, { useState } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { setScrollTrigger } from "../../api/xoicom.reducer";
import { staggerDisplay } from "../../utils/animationUtils";

function FoodImage({ step, index, active, mobile, windowWidth }) {
  const { zIndex, title, imageSrc } = step;
  const position = () => {
    const top = (step.top / 1440) * windowWidth;
    const left = (step.left / 1440) * windowWidth;
    const width = (step.size.width / 1440) * windowWidth;

    return {
      top: mobile ? top * 1.4 : top,
      left: mobile ? left * 1.3 : left,
      width: mobile ? width * 1.5 : width * 1.1,
    };
  };

  return (
    <motion.div
      style={{ position: "absolute", zIndex, ...position(step) }}
      variants={staggerDisplay}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
      custom={index}
    >
      <SlideImage
        id={index}
        className={`food-graphic ${active ? "food-graphic-zoom" : ""}`}
        alt={title}
        imageSrc={imageSrc}
      />
    </motion.div>
  );
}

function SlideImage(props) {
  const { display, id, alt, imageSrc, className } = props;
  const [startNumber, setStartNumber] = useState(0);
  const duration = 1000;
  const dispatch = useDispatch();
  const countUp = () => {
    if (display === "block") {
      if (startNumber < 2) {
        setTimeout(() => {
          setStartNumber(startNumber + 1);
        }, duration);
      } else {
        setStartNumber(startNumber);

        //trigger next element
        dispatch(setScrollTrigger(id));
      }
    }
  };
  return (
    <motion.img
      className={className}
      alt={alt || "about-number"}
      src={imageSrc}
      whileInView={() => countUp()}
    />
  );
}

export default FoodImage;
