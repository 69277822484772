import { createAsyncThunk } from "@reduxjs/toolkit";
import { spaceImagesApi, spaceTextApi } from "./space.wpapi";

export const fetchSpaceImages = createAsyncThunk(
  "space-image/get",
  async () => {
    const response = await spaceImagesApi();
    return response;
  }
);

export const fetchSpaceText = createAsyncThunk("space-text/get", async () => {
  const response = await spaceTextApi();
  return response;
});
