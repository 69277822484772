import React, { useEffect, useState } from "react";

import AddressSession from "../session/AddressSession";
import "../style/Blog.css";
import "../style/Slide.css";
import { motion } from "framer-motion";

import { useDispatch, useSelector } from "react-redux";
import { setNavBarMode } from "../api/xoicom.reducer";
import BlogListFull from "../components/blog/BlogListFull";
import LoadingBackdrop from "../components/skeleton/LoadingBackdrop";
import { IMAGES, hasData, saveToStorage, scrollToTop } from "../utils/utils";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../utils/responsiveUtils";
import { fetchBlogs, fetchBlogDetails } from "../api/blog/blog.reduxThunk";
import { BLOG_LANDING_TEXT } from "../utils/blogUtils";
import SlideWrapper from "../components/generics/SlideWrapper";
import SwipeWrapper from "../components/generics/SwipeWrapper";
import { slideUp } from "../utils/animationUtils";
import XoiComHeading from "../components/generics/XoiComHeading";
import { useNavigate } from "react-router-dom";
import { BLOG } from "../api/utils.api";

const getFilteredBlogs = (allBlogs, filter) => {
  if (!filter || filter === BLOG) return allBlogs;

  return allBlogs.filter((blog) => blog.categories.includes(filter));
};

function CategoryButton({ category, active, handleClick }) {
  const { label } = category;
  const activeClass = active ? "active" : "default";
  return (
    <button
      onClick={handleClick}
      className={`custom-button-slide custom-button-slide-${activeClass}`}
    >
      {label}
    </button>
  );
}

function Blog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobile = useMediaQuery(breakpoint.mobile);

  const blogs = useSelector((state) => state.reducer.blogs);
  const blogText = useSelector((state) => state.reducer.blogText);
  const loading = useSelector((state) => state.reducer.loading);
  const language = useSelector((state) => state.reducer.language);
  const categories = useSelector((state) => state.reducer.blogCategories);

  const [activeCategory, setActiveCategory] = useState(BLOG);

  const featureBlogs = blogs?.filter((blog) => blog.featureBlog) || [];
  const filteredBlogs = getFilteredBlogs(blogs, activeCategory);

  const hasBlogs = hasData(blogs);

  useEffect(() => {
    scrollToTop();
    hasBlogs && dispatch(setNavBarMode("light"));
    setActiveCategory(BLOG);
  }, []);

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [language]);

  useEffect(() => {
    blogs && saveToStorage("BLOG", blogs);
  }, [blogs]);

  const handleClick = (category) => {
    setActiveCategory(category.id);
  };

  const handleClickTitle = (blog) => {
    dispatch(fetchBlogDetails(blog.id))
      .unwrap()
      .then(() => {
        navigate(`/blog/${blog.id}`);
      });
  };

  return (
    <div>
      <LoadingBackdrop open={loading} />
      {!loading && (
        <div>
          {hasBlogs && hasData(featureBlogs) ? (
            <div className="blog-highlight-session">
              {mobile ? (
                <SwipeWrapper
                  steps={featureBlogs}
                  mode="image-slide"
                  preview
                  handleClickTitle={handleClickTitle}
                />
              ) : (
                <SlideWrapper
                  steps={featureBlogs}
                  mode="image-slide"
                  preview
                  handleClickTitle={handleClickTitle}
                />
              )}
            </div>
          ) : (
            <div className="landing-session">
              <img
                className="landing-image"
                src={IMAGES.ABOUT_LANDING}
                alt="blog-landing"
              />
              <motion.div
                className="landing-text"
                style={{ maxWidth: 700 }}
                variants={slideUp}
                initial="initial"
                whileInView="animate"
                viewport={{ once: true }}
              >
                <XoiComHeading
                  heading={BLOG_LANDING_TEXT}
                  textColor="white"
                  bigHeader
                />
              </motion.div>
            </div>
          )}
        </div>
      )}
      <div className="more-blog-container">
        <XoiComHeading mode="h2" heading={blogText.heading} />
        {/* categories */}
        {categories && (
          <div className={`category-container${mobile ? "-mobile" : ""}`}>
            {categories.map((category) => (
              <CategoryButton
                category={category}
                active={category.id === activeCategory}
                handleClick={() => handleClick(category)}
              />
            ))}
          </div>
        )}
        {hasBlogs ? (
          <BlogListFull blogs={filteredBlogs} loading={loading} />
        ) : (
          <h3>More blogs incoming ...</h3>
        )}
      </div>
      <AddressSession mode="cta" />
    </div>
  );
}

export default Blog;
