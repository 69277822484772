import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import "../../style/Slide.css";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import PressReviewSlide from "../home/PressReviewSlide";
import FoodSlide from "../home/FoodSlide";
import SpiceSlide from "../about/SpiceSlide";
import HistoryDetails from "../about/HistoryDetails";
import ImagePopupSlide from "./ImagePopupSlide";
import { breakpoint } from "../../utils/responsiveUtils";
import ImageSlide from "./ImageSlide";

const ANIMATION = 200; //ms

const SlideButton = ({ onClick, color, disabled, next, back }) => {
  return (
    <IconButton
      className="slide-button"
      disabled={disabled}
      onClick={onClick}
      style={{ color: color }}
    >
      {back && <WestIcon />}
      {next && <EastIcon />}
    </IconButton>
  );
};

function SlideWrapper({
  steps,
  selected,
  mode,
  openPopup,
  handleClosePopup,
  preview,
  handleClickTitle,
}) {
  const language = useSelector((state) => state.reducer.language);
  const foodSteps = steps && steps.slice(0, steps.length - 1);
  const mobile = useMediaQuery(breakpoint.mobileSm);

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [clicked, setClicked] = useState([true, ...Array(4).fill(false)]);
  const [toBeClicked, setToBeClicked] = useState([
    false,
    true,
    ...Array(3).fill(false),
  ]);
  const [display, setDisplay] = useState(Array(5).fill(false));
  const [autoCount, setAutoCount] = useState(0);
  const [animation, setAnimation] = useState(true);

  const totalSteps = () => {
    return mode === "food" ? foodSteps.length : steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  useEffect(() => {
    setAnimation(true);
  }, [activeStep]);

  useEffect(() => {
    if (selected !== undefined) {
      setActiveStep(selected);
    }
  }, [selected]);

  const handleNext = () => {
    //set animation
    setAnimation(false);
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          mode === "food"
          ? foodSteps.findIndex((step, i) => !(i in completed))
          : steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setTimeout(() => {
      setActiveStep(newActiveStep);
    }, ANIMATION);
    //set display to true one by one
    if (mode === "image-slide") {
      display.splice(activeStep, 1, true);
    }
  };

  const handleBack = () => {
    setAnimation(false);
    setTimeout(() => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, ANIMATION);
  };

  const handleClick = (step) => {
    //set toBeClicked to true for the next element
    if (step < toBeClicked.length - 1) {
      toBeClicked.splice(0, step + 1, ...Array(step + 1).fill(false));
      toBeClicked.splice(step + 1, 1, true);
      toBeClicked.splice(
        step + 2,
        toBeClicked.length - step - 2,
        ...Array(toBeClicked.length - step - 2).fill(false)
      );
    } else {
      setToBeClicked(Array(toBeClicked.length).fill(false));
    }

    //set clicked to true one by one
    clicked.splice(step, 1, true);
  };

  const handleStep = (step) => () => {
    if (step !== activeStep) {
      setAnimation(false);
    }
    setTimeout(() => {
      setActiveStep(step);
      if (mode === "spice") {
        handleClick(step);
      }
    }, ANIMATION);
  };

  const autoNext = () => {
    setTimeout(() => {
      if (autoCount < steps.length && !display[autoCount]) {
        handleNext();
        setAutoCount(autoCount + 1);
      }
    }, 3000);
  };

  const backButton = (
    <SlideButton
      disabled={activeStep === 0}
      onClick={handleBack}
      color={mode.startsWith("image") && "white"}
      back
    />
  );

  const nextButton = (
    <SlideButton
      className="slide-button"
      onClick={handleNext}
      color={mode.startsWith("image") && "white"}
      next
    />
  );

  return (
    <Box className={mode === "spice" ? "" : "slide-container"}>
      {mode === "review" && (
        <PressReviewSlide
          steps={steps}
          activeStep={activeStep}
          backButton={backButton}
          nextButton={nextButton}
          animation={animation}
        />
      )}
      {mode === "food" && (
        <FoodSlide
          steps={steps}
          foodSteps={foodSteps}
          activeStep={activeStep}
          backButton={backButton}
          nextButton={nextButton}
          handleStep={handleStep}
          animation={animation}
        />
      )}
      {mode === "spice" && (
        <SpiceSlide
          steps={steps}
          language={language}
          activeStep={activeStep}
          clicked={clicked}
          toBeClicked={toBeClicked}
          handleStep={handleStep}
          animation={animation}
        />
      )}
      {mode === "history" && (
        <HistoryDetails
          steps={steps}
          activeStep={activeStep}
          handleStep={handleStep}
          animation={animation}
        />
      )}
      {mode === "image-popup" && (
        <ImagePopupSlide
          steps={steps}
          activeStep={activeStep}
          backButton={backButton}
          nextButton={nextButton}
          open={openPopup}
          handleCloseSlide={handleClosePopup}
          handleStep={handleStep}
          mobile={mobile}
          animation={animation}
        />
      )}
      {mode === "image-slide" && (
        <ImageSlide
          steps={steps}
          activeStep={activeStep}
          backButton={backButton}
          nextButton={nextButton}
          autoNext={autoNext}
          blogPreview={preview}
          handleClickTitle={handleClickTitle}
        />
      )}
    </Box>
  );
}

export default SlideWrapper;
