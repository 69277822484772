import React from "react";
import "../../style/Slide.css";
import { motion } from "framer-motion";

import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import CloseButton from "./CloseButton";
import SwipeWrapper from "./SwipeWrapper";

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialog-container": {
    height: "100vh",
  },
  "& .MuiDialog-paper": {
    backgroundColor: "transparent",
    boxShadow: "0px 0px",
    width: "100%",
    maxWidth: 1000,
    margin: "auto",
    top: "20px",
    maxHeight: 1024,
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
}));

function ImagePopupSlide({
  steps,
  activeStep,
  nextButton,
  backButton,
  open,
  handleCloseSlide,
  handleStep,
  mobile,
  animation,
}) {
  return (
    <div>
      <StyledDialog onClose={handleCloseSlide} open={open}>
        {mobile ? (
          <div className="space-container-mobile">
            <div className="space-slide-container-mobile">
              <SwipeWrapper
                selected={activeStep}
                steps={steps}
                ratio="vertical"
                mode="image-slide"
                auto={false}
              />
            </div>
          </div>
        ) : (
          <div className="space-container">
            <CloseButton onClick={handleCloseSlide} left="92%" top="0px" />
            {backButton}
            <div className="space-image-container-preview">
              <motion.div className="space-slide-container">
                {steps.map((step) => (
                  <Image
                    key={step.id || step.title}
                    step={step}
                    activeStep={activeStep}
                  />
                ))}
              </motion.div>
              <div className="image-preview-container">
                {steps.map((step, index) => (
                  <PreviewImage
                    key={step.id || step.title}
                    step={step}
                    active={index === activeStep && animation}
                    onClick={handleStep(index)}
                  />
                ))}
              </div>
            </div>
            {nextButton}
          </div>
        )}
      </StyledDialog>
    </div>
  );
}

function Image({ step, activeStep }) {
  return (
    <img
      className="blog-slide-background"
      alt=""
      style={{
        translate: `${-100 * activeStep}%`,
        transition: "translate 300ms ease-in-out",
      }}
      src={step.image}
      viewport={{ once: true }}
    ></img>
  );
}

function PreviewImage({ step, active, onClick }) {
  const imagePreviewClass = `image-preview-${active ? "active" : "default"}`;

  return (
    <img
      className={`image-preview clickable ${imagePreviewClass}`}
      alt=""
      src={step.image}
      viewport={{ once: true }}
      onClick={onClick}
    ></img>
  );
}

export default ImagePopupSlide;
