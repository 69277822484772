import React, { useEffect, useRef } from "react";
import AddressSession from "../session/AddressSession";

import "../style/Session.css";
import { useDispatch, useSelector } from "react-redux";
import { setChosenLocationEmpty, setNavBarMode } from "../api/xoicom.reducer";
import {
  IMAGES,
  getLanguageVersionFromStorage,
  checkOthers,
} from "../utils/utils";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import IngredientBlog from "../components/ingredient/IngredientBlog";
import IngredientBlogMobile from "../components/ingredient/IngredientBlogMobile";

import { fetchIngredientDetails } from "../api/ingredient/ingredient.reduxThunk";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../utils/responsiveUtils";
import LoadingBackdrop from "../components/skeleton/LoadingBackdrop";
import { slideUp } from "../utils/animationUtils";
import XoiComHeading from "../components/generics/XoiComHeading";

function IngredientDetail() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const language = useSelector((state) => state.reducer.language);
  const ingredient = useSelector((state) => state.reducer.ingredientDetails);
  const ingredientText = useSelector((state) => state.reducer.ingredientText);
  const loading = useSelector((state) => state.reducer.loading);
  const mobileSm = useMediaQuery(breakpoint.mobileSm);

  useEffect(() => {
    getIngredientDetails();
  }, [language]);

  const ref = useRef(null);
  useEffect(() => {
    dispatch(setNavBarMode("light"));
    setTimeout(() => {
      ref?.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  }, []);

  const getIngredientDetails = () => {
    const multiLanguageId =
      getLanguageVersionFromStorage("INGREDIENT", Number(id), language) || id;
    dispatch(fetchIngredientDetails(multiLanguageId))
      .unwrap()
      .then(() => navigate(`/ingredient/${multiLanguageId}`));
  };

  const handleBack = (fullReset) => {
    const othersExit = checkOthers("INGREDIENT", ingredient.id);
    if (fullReset || !othersExit) {
      dispatch(setChosenLocationEmpty());
    }
    navigate("/ingredient");
  };

  return (
    <div>
      <LoadingBackdrop open={loading} />
      <div className="landing-session">
        <img
          className="landing-image"
          src={IMAGES.INGREDIENT_LANDING}
          alt="ingredient-landing"
        />
        <motion.div
          className="landing-text"
          style={{ maxWidth: 700 }}
          variants={slideUp}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
        >
          <XoiComHeading
            heading={ingredientText.landingText}
            textColor="white"
            bigHeader
          />
        </motion.div>
      </div>
      <div ref={ref}>
        {ingredient && !mobileSm && (
          <IngredientBlog
            ingredient={ingredient}
            handleBack={handleBack}
            ingredientText={ingredientText}
          />
        )}
        {ingredient && mobileSm && (
          <IngredientBlogMobile
            ingredient={ingredient}
            handleBack={handleBack}
            ingredientText={ingredientText}
          />
        )}
      </div>
      <AddressSession mode="cta" />
    </div>
  );
}

export default IngredientDetail;
