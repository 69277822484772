export const TEXT_VN = {
  pages: {
    about: "Triết lý",
    // ingredient: "Nguyên liệu",
    space: "Không gian",
    blog: "Nấu và Ăn",
    contact: "Đặt bàn",
    menu: "Menu",
  },
  home: "Trang chủ",
  contactUs: "Kết nối với chúng tôi:",
  language: "Ngôn ngữ:",
};

export const TEXT_EN = {
  pages: {
    about: "About",
    // ingredient: "Ingredient",
    space: "Space",
    blog: "Blog",
    contact: "Contact",
    menu: "Menu",
  },
  home: "Home",
  contactUs: "Contact us:",
  language: "Language:",
};
