import React from "react";
import "../../style/Reusable.css";
import "../../style/components/BlogList.css";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import LazyImage from "../generics/LazyImage";
import { fetchBlogDetails } from "../../api/blog/blog.reduxThunk";

const getCategoryString = (blogCategories) => {
  let categoryString;
  if (blogCategories) {
    categoryString = blogCategories.filter((category) => category);
  }
  const categories = categoryString.join(" • ");
  if (!categories) return "";
  else return ` • ${categories}`;
};

function BlogCard({ blog }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(fetchBlogDetails(blog.id))
      .unwrap()
      .then(() => {
        navigate(`/blog/${blog.id}`);
      });
  };

  return (
    <div className="blog-card" onClick={handleClick}>
      <div className="blog-card-image-container">
        <LazyImage
          className="zoom"
          imgClass="blog-card-image"
          imageSrc={blog.previewImage}
          alt={blog.title}
        />
      </div>
      <div className="blog-card-text">
        <div className="blog-card-text-heading body-b2">{blog.heading}</div>
        <div className="blog-card-subtitle">
          {blog.date}
          {getCategoryString(blog.categoryDisplayName)}
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
