import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setNavBarMode } from "../api/xoicom.reducer";
import "../style/BlogDetails.css";
import BlogListFull from "../components/blog/BlogListFull";
import { Divider } from "@mui/material";
import AddressSession from "../session/AddressSession";
import { useMediaQuery } from "@mui/material";
import LoadingBackdrop from "../components/skeleton/LoadingBackdrop";
import {
  getLanguageVersionFromStorage,
  responsiveBlogPreview,
  scrollToTop,
  toElement,
} from "../utils/utils";
import { breakpoint } from "../utils/responsiveUtils";
import { fetchBlogs, fetchBlogDetails } from "../api/blog/blog.reduxThunk";
import SVGIcon from "../components/generics/SVGIcon";
import { getFullWidthImage } from "../utils/blogUtils";

function Category({ category }) {
  return (
    <span key={category} className="blog-card-category">
      <svg width="4" height="4">
        <circle cx="2" cy="2" r="2" fill="#848484" />
      </svg>
      <span>{category}</span>
    </span>
  );
}

function SocialShare({ title }) {
  return (
    <div className="social-share-container">
      <span className="cap-m15">{title}</span>
      {["facebook", "instagram"].map((icon) => (
        <span key={icon}>
          <button className="custom-icon-button">
            <SVGIcon icon={icon} color="#9A9A9A" />
          </button>
        </span>
      ))}
    </div>
  );
}

function ContentParagraph({ content, fullWidth }) {
  const { props } = content;
  const isImage = props.children.type === "img";

  const { props: propsChild } = props.children;
  const isFullImage =
    fullWidth && isImage && propsChild["data-attachment-id"] === fullWidth;

  return (
    <div
      className={`content-paragraph body-b1 ${isFullImage ? "full-image" : ""}`}
    >
      {content}
    </div>
  );
}

function BlogDetail() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const blog = useSelector((state) => state.reducer.blogDetails);
  const blogs = useSelector((state) => state.reducer.blogs);
  const loading = useSelector((state) => state.reducer.loading);
  const blogText = useSelector((state) => state.reducer.blogText);
  const language = useSelector((state) => state.reducer.language);

  const [fullWidth, setFullWidth] = useState();

  const medium = useMediaQuery(breakpoint.medium);
  const large = useMediaQuery(breakpoint.large);

  const extrablogs = blogs && responsiveBlogPreview(blogs, { medium, large });
  const { imagePreview, heading, date, category, contentParagraph } =
    blog || {};

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    scrollToTop();
    dispatch(setNavBarMode("light"));
    setImageFullWidth();
  }, [blog]);

  useEffect(() => {
    dispatch(fetchBlogs());
    getBlogDetails();
  }, [language]);

  const getBlogDetails = () => {
    const multiLanguageId = getLanguageVersionFromStorage(
      "BLOG",
      Number(id),
      language
    );
    dispatch(fetchBlogDetails(multiLanguageId))
      .unwrap()
      .then(() => navigate(`/blog/${multiLanguageId}`));
  };

  const setImageFullWidth = () => {
    setFullWidth();

    if (blog?.imageFullWidth) {
      const fullWidth = blog.imageFullWidth;
      const image = getFullWidthImage(blog.contentParagraph, fullWidth);

      setFullWidth(image);
    }
  };

  return (
    <div>
      <LoadingBackdrop open={loading} />
      {blog && (
        <div>
          <img
            className="blog-image-preview"
            alt="blog-preview"
            src={imagePreview}
          />
          <div className="blog-details-heading-container">
            <h1 className="blog-details-heading">{heading}</h1>
            <div className="body-b2 blog-details-subtitle">
              <span>{date}</span>
              {category?.map((category) => (
                <Category
                  key={category.toLowerCase().replace(/ /g, "-")}
                  category={category}
                />
              ))}
            </div>
          </div>
          <div className="blog-details-content-container">
            {contentParagraph.map((content, index) => (
              <ContentParagraph
                key={index}
                content={toElement(content)}
                fullWidth={fullWidth}
              />
            ))}
          </div>
          <div className="blog-details-divider-container">
            <Divider className="blog-details-divider" />
          </div>
          <SocialShare title={blogText.share} />
        </div>
      )}
      <div style={{ marginTop: 120 }}>
        <h2>{blogText.more}</h2>
        {extrablogs && <BlogListFull blogs={extrablogs} loading={loading} />}
      </div>
      <AddressSession mode="cta" />
    </div>
  );
}

export default BlogDetail;
