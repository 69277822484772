import React from "react";

import "../../style/NavBar.css";
import LanguageButton from "./LanguageButton";
import PageButton from "./PageButton";

function NavbarPC(props) {
  const {
    logo,
    pages,
    activePage,
    navbar,
    mode,
    color,
    language,
    changeLanguage,
  } = props;
  return (
    <div sx={{ flexGrow: 1 }}>
      <div className="navbar-grid">
        <div className="navbar-logo-grid" style={{ padding: 0 }}>
          {/* Xoi Com Logo */}
          {logo}
        </div>
        <div className="pages">
          {pages.map((page) => (
            <Page
              key={page.name}
              page={page}
              navbar={navbar}
              mode={mode}
              color={color}
              active={activePage === page.name}
            />
          ))}
        </div>
        <div className="navbar-button-grid">
          <div className="language">
            <ButtonGroup
              changeLanguage={changeLanguage}
              language={language}
              color={color}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Page({ page, navbar, active, mode, color }) {
  const scrollNavbarClass = active ? "murrey" : "black-hover";
  const lightNavbarClass = active ? "white-active" : "white";
  const darkNavbarClass = active ? "murrey" : "black-hover";
  const navbarClass = mode === "light" ? lightNavbarClass : darkNavbarClass;
  const pageClass = navbar ? scrollNavbarClass : navbarClass;

  return (
    <div className="page">
      <PageButton page={page} pageClass={pageClass} color={color} />
    </div>
  );
}

function ButtonGroup({ changeLanguage, language, color }) {
  return (
    <>
      <LanguageButton
        language="en"
        active={language === "en"}
        changeLanguage={changeLanguage}
        color={color}
      />
      <button className={`custom-button-language non-clickable ${color}`}>
        -
      </button>
      <LanguageButton
        language="vn"
        active={language === "vn"}
        changeLanguage={changeLanguage}
        color={color}
      />
    </>
  );
}

export default NavbarPC;
