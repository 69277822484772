import React from "react";
import TextField from "@mui/material/TextField";
import "../../style/Reusable.css";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(() => ({
  "& label": {
    color: "rgba(255, 255, 255, 0.5)",
    fontFamily: "League Spartan",
  },
  "& label.Mui-focused": { color: "#FFF7EA" },
  "& label.Mui-error": { color: "#717171" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "rgba(255, 255, 255, 0.12)" },
    "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.32)" },
    "&.Mui-focused fieldset": { borderColor: "rgba(255, 255, 255, 0.32)" },
    "&.Mui-error fieldset": { borderColor: "#717171" },
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: "League Spartan",
    color: "#FFF7EA",
    "&:focus": { color: "#FFF7EA" },
  },
}));

function Input({
  name,
  value,
  error,
  label,
  onChange,
  multiline = false,
  rows,
}) {
  return (
    <StyledTextField
      required
      name={name}
      value={value}
      error={error}
      label={label}
      onChange={onChange}
      multiline={multiline}
      rows={rows}
      fullWidth
    />
  );
}

export default Input;
