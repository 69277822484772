import { formatDateMonthYear } from "./utils";

export const TEXT_VN = {
  landingText: "::Triết lý ẩm thực::trong từng món ngon",
  memory: {
    heading: "Công thức không tạo nên món ăn ngon!",
    bodyText:
      "Acid tạo điểm nhấn tươi sáng cho món ăn. Muối thì làm giảm bớt vị chua. Ớt (cay) thì làm nổi bật các vị khác của món ăn. Mỡ thì giúp đồ ăn chiên, xào có mùi vị thơm đặc trưng để bên trong thì chín mềm, bên ngoài thì giòn tan.",
  },
  origin: [
    {
      type: "reg",
      text: "Chúng tôi làm việc trực tiếp với nông hộ để đem các loại rau củ mới hái, tươi ngon,",
    },
    { type: "accent", text: "từ những mảnh đất màu mỡ" },
    {
      type: "reg",
      text: `về thẳng nhà bếp. Bằng cách cắt giảm tối đa thời gian vận chuyển, công đoạn trung gian — qua chợ đầu mối, chúng tôi giữ cho nguyên liệu của mình "sống", để đảm bảo lưu giữ tối đa các`,
    },
    { type: "accent", text: "chất dinh dưỡng, cũng như sự tươi lành" },
    {
      type: "reg",
      text: "từ bàn tay chúng tôi đến bàn ăn, nơi các bạn đang ngồi.",
    },
  ],
  journey: "Hành trình của xới cơm",
  process: "Cơm ngon nhờ được::chăm chút::trong từng công đoạn",
  green: "Những bữa cơm với tinh thần “::xanh::”",
};
export const TEXT_EN = {
  landingText: "Gastronomic::Philosophy",
  memory: {
    heading: "The Essence of Cooking Lies Beyond Recipies!",
    bodyText:
      "Acid adds a bright, refreshing touch to dishes. Salt helps balance out sour flavour. Spiciness accentuates the other flavors in a dish. Fat imparts a distinctive aroma to fried and stir-fried dishes, ensuring that the insides are moist and tender, while the outside become crispy.",
  },
  origin: [
    {
      type: "reg",
      text: "We work directly with our growers to receive freshly unearthed vegetables, straight",
    },
    { type: "accent", text: "from the rich soil" },
    {
      type: "reg",
      text: "into our kitchen. By skipping the Farmer's Market we keep the food alive — ensuring peak nutritional",
    },
    { type: "accent", text: "value and deliciousness" },
    { type: "reg", text: "from our hands to your table." },
  ],
  journey: "Our Journey",
  process: "::Nuturing Flavors::in Every Step",
  green: "“::Eco-Conscious::” Menu",
};

export const PROCESS_CARDS = [
  {
    title: "fresh-ingredient",
    description: { vn: "Nguyên liệu tươi ngon", en: "Fresh Ingredients" },
    imageSrc: "assets/About/Process/process1.png",
  },
  {
    title: "carefully-selected",
    description: { vn: "Tuyển chọn cẩn thận", en: "Carefully Selected" },
    imageSrc: "assets/About/Process/process2.png",
  },
  {
    title: "meticulously-prepared",
    description: { vn: "Chế biến tỉ mỉ", en: "Meticulously Prepared" },
    imageSrc: "assets/About/Process/process3.png",
  },
  {
    title: "daily-fresh-delights",
    description: { vn: "Món mới mỗi ngày", en: "Daily Fresh Delights" },
    imageSrc: "assets/About/Process/process4.png",
  },
  {
    title: "sustainable-leftover-practices",
    description: { vn: "Xử lý đồ thừa", en: "Sustainable Leftover Practices" },
    imageSrc: "assets/About/Process/process5.png",
  },
];

export const NUMBER_CARDS = [
  {
    number: "100%",
    description: {
      vn: "Sử dụng nguyên liệu địa phương, an toàn",
      en: "Food safety licenses",
    },
  },
  {
    number: 35730,
    description: {
      vn: "Chai, hũ thủy tinh tái sử dụng",
      en: "Glass bottles recycled",
    },
  },
  {
    number: 7685,
    description: {
      vn: "Thực phẩm dư thừa được tái xử lý cho nông nghiệp",
      en: "Reprocessed leftover food for agricultural use",
    },
  },
];

export const SPICE_CARDS = [
  {
    id: "acid",
    name: { vn: "Acid", en: "Acids" },
    description: {
      vn: "Acid tự nhiên ở đất nước nhiệt đới là vị chua trong trái quất, tép chanh, quả mận, quả mơ... hoặc Acid phát triển trong quá trình lên men từ đường như: giấm bỗng, mẻ… Acid tạo điểm nhấn tươi sáng cho món ăn, giúp cân bằng hương vị. Không thể thiếu Acid để nấu một mâm cơm Việt Nam ngon, lành, nhất là vào mùa hè.",
      en: "Nature acids in tropical countries are the sour taste in kumquats, lemon wedges, plums, apricots, etc. Or acids that develop during the fermentation of sugar, such as vinegar, giấm bỗng (fermented rice), mẻ (fermented rice paste). Acids add a bright accent to dishes and help balance the flavor. They are essential for cooking a delicious and healthy Vietnamese meal, especially in the summer.",
    },
    image: "acid",
    imageSrc: "/assets/About/Spice/Acid.jpg",
  },
  {
    id: "salt",
    name: { vn: "Muối", en: "Salt" },
    description: {
      vn: "Muối của biển, đặc trưng cho Việt Nam. Muối không chỉ thêm vào để bổ sung khoáng cho cơ thể, nó góp phần tăng cường sự đa dạng về hương vị và đặc tính của món ăn. Bờ biển Việt Nam dài hơn 3.260km trải dài từ Bắc xuống Nam, là điều kiện tuyệt vời để khai thác muối. Một nhúm muối ngon, thực sự làm thay đổi hương vị của món ăn.",
      en: "Salt of the sea, a hallmark of Vietnamese cuisine, goes beyond simply adding minerals to our bodies. It plays a crucial role in enhancing the flavor and character of our dishes. Stretching over 3,260 kilometers from north to south, Vietnam's coastline provides an abundance of natural resources for salt production. A sprinkle of fine sea salt can truly transform ordinary into the extraordinary.",
    },
    image: "salt",
    imageSrc: "/assets/About/Spice/Salt.jpg",
  },
  {
    id: "spice",
    name: { vn: "Cay", en: "Spices" },
    description: {
      vn: "Khác với 4 vị ngọt, chua, mặn, đắng, ta chỉ nhận thấy cay qua cảm giác nhiều hơn là vị giác. Ớt (ớt Huế, ớt trung đoàn, ớt thóc, ớt chỉ thiên…), hạt tiêu, gừng thường đem đến cảm giác cay nóng và lá bạc hà cho ta cảm giác cay lạnh. Không chỉ tạo nên sự đặc trưng, mà cay còn giúp kích thích và làm bùng nổ cảm xúc trong bữa ăn.",
      en: "Unlike its sweet, sour, salty, and bitter counterparts, spice is not solely perceived by our taste buds. It is a sensory experience.  Chili pepper (Huế chili pepper, Vietnamese reaper chili pepper, Bird's eye chili pepper, Facing heaven chili pepper, etc) typically imparts a burning and spicy sensation. While black peppercorn and ginger brings the warmth, mint leaves provide a refreshing, tingling sensation. They add a distinctive flavor and help stimulate, ignite emotions during the meal.",
    },
    image: "spice",
    imageSrc: "/assets/About/Spice/Spice.jpg",
  },
  {
    id: "texture",
    name: { vn: "Chất béo", en: "Fats" },
    description: {
      vn: "Nếu sử dụng đúng cách và đúng nhiệt độ lý tưởng, nó giúp đồ ăn có mùi, vị thơm đặc trưng. Miếng thịt heo mỡ có vị ngon và mùi thơm phong phú hơn thịt nạc, bất kể cách chế biến là luộc, xào, chiên hay nướng.",
      en: "When used correctly and at the ideal temperature, it imparts a distinctive aroma and flavor to food. Fatty pork has a richer flavor and aroma than lean pork, regardless of the cooking method, whether it is boiled, stir-fried, deep-fried, or grilled.",
    },
    image: "texture",
    imageSrc: "/assets/About/Spice/Texture.jpg",
  },
  {
    id: "maillard",
    name: { vn: "Nhiệt - lửa", en: "Heat and Flames" },
    description: {
      vn: "Chiếc chảo với ngọn lửa lớn bùng cháy là đặc trưng của bếp Việt Nam. Ngọn lửa ở cường độ khác nhau và thời gian khác nhau tạo ra mùi và vị khác nhau. Trong hóa học gọi là phản ứng maillard, nó là một phản ứng hấp dẫn, kích thích mọi vị giác và nâng tầm hương vị món ăn lên trên sự mong đợi.",
      en: "The crackling flames of a large wok are a hallmark of Vietnamese cuisine. The flames, at varying intensities and durations, produce distinct aromas and flavors. In chemistry, this is known as the Maillard reaction, a captivating process that tantalizes all taste buds and elevates the culinary experience beyond expectations.",
    },
    image: "maillard",
    imageSrc: "/assets/About/Spice/Maillard.jpg",
  },
];

export const SPICE_TEXT_GROUPS = [
  [0, 2],
  [2, 4],
  [4, 5],
];

export const SPICE_TEXT_GROUPS_MB = [
  [0, 3],
  [3, 5],
];

export const getFormattedHistory = (post, image, historyContent) => {
  const { date, slug } = post;
  const { heading, description } = historyContent;
  return {
    date: formatDateMonthYear(date),
    heading,
    description,
    image,
    alt: slug,
  };
};
