import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  aboutNumberApi,
  backgroundApi,
  contactApi,
  ingredientParallaxApi,
  sendEmailApi,
} from "./wpapi";
export const fetchHomeBG = createAsyncThunk("home/get", async () => {
  const response = await backgroundApi();
  return response;
});

export const fetchIngredientParallax = createAsyncThunk(
  "ingredient-para/get",
  async () => {
    const response = await ingredientParallaxApi();
    return response;
  }
);

export const fetchAboutNumber = createAsyncThunk(
  "about-number/get",
  async () => {
    const response = await aboutNumberApi();
    return response;
  }
);

export const fetchContact = createAsyncThunk("contact/get", async () => {
  const response = await contactApi();
  return response;
});

export const sendEmail = createAsyncThunk("email/post", async (data) => {
  const response = await sendEmailApi(data);
  return response;
});
