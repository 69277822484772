import React, { useEffect, useState } from "react";
import "../../style/Slide.css";
import "../../style/components/BlogSlide.css";
import Grid from "@mui/material/Unstable_Grid2";
import { motion } from "framer-motion";
import StepperDots from "./StepperDots";

function ImageSlide({
  steps,
  activeStep,
  backButton,
  nextButton,
  autoNext,
  blogPreview,
  handleClickTitle,
}) {
  const [auto, setAuto] = useState(false);
  useEffect(() => {
    auto && autoNext();
  }, [activeStep, auto]);

  return (
    <div className="image-slide-wrapper" style={{ height: "100%" }}>
      <motion.div
        className="blog-slide-container"
        whileInView={() => setAuto(true)}
      >
        {steps.map((step) => (
          <Image
            key={step.id}
            src={blogPreview ? step.previewImage : step.image}
            activeStep={activeStep}
          />
        ))}
      </motion.div>
      <div className="text-button blog-slide-text-button">
        <div className="blog-slide-grid-container">
          <Grid container spacing={2} className="blog-slide-grid">
            <Grid xs={2}>{backButton}</Grid>
            <Grid xs={8} className="blog-slide-content-grid">
              {/* content */}
              <div className="blog-slide-content">
                {steps.map(
                  (step) =>
                    step.heading && (
                      <Title
                        key={step.id}
                        step={step}
                        activeStep={activeStep}
                        onClick={() => handleClickTitle(step)}
                      />
                    )
                )}
              </div>
            </Grid>
            <Grid xs={2}>{nextButton}</Grid>
          </Grid>
        </div>
        <div className="blog-slide-dots-container">
          <StepperDots length={steps.length} activeStep={activeStep} />
        </div>
      </div>
    </div>
  );
}

const getTranslation = (step) => {
  return {
    translate: `${-100 * step}%`,
    transition: "all 300ms ease-in-out",
  };
};

function Image({ src, activeStep }) {
  return (
    <img
      alt=""
      className="blog-slide-background"
      style={getTranslation(activeStep)}
      src={src}
      viewport={{ once: true }}
    ></img>
  );
}

function Title({ step, activeStep, onClick }) {
  return (
    <div
      className="body-b2 blog-slide-content-text clickable"
      style={getTranslation(activeStep)}
      onClick={onClick}
    >
      <div className="header-text blog-slide-heading">{step.heading}</div>
    </div>
  );
}

export default ImageSlide;
