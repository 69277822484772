import { EMAIL_URL } from "../utils/utils";
import {
  ABOUT_NUMBER,
  BACKGROUND,
  CONTACT_BOOKING,
  CONTACT_POLICY,
  INGREDIENT_PARALLAX,
  fetchByCategory,
  getMultiLanguagePost,
} from "./utils.api";

//get home background image
export const backgroundApi = async () => {
  const data = await fetchByCategory(BACKGROUND);
  //get first result
  return data[0];
};

//get ingredient parallax
export const ingredientParallaxApi = async () => {
  const data = await fetchByCategory(INGREDIENT_PARALLAX);
  //get the first post that match the params
  return data[0];
};

//get number for about page
export const aboutNumberApi = async () => {
  const data = await fetchByCategory(ABOUT_NUMBER);
  //get the first post that match the params
  return data[0];
};

export const contactApi = async () => {
  const bookingData = await fetchByCategory(CONTACT_BOOKING);
  const policyData = await fetchByCategory(CONTACT_POLICY);

  const contactData = {
    booking: getMultiLanguagePost(bookingData),
    policy: getMultiLanguagePost(policyData),
  };
  //get all results
  return contactData;
};

//send email
const endpointUrl = EMAIL_URL;
export const sendEmailApi = async (data) => {
  fetch(endpointUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      // Endpoint thinks that it's likely a spam/bot request, you need to change "spam protection mode" to "never" in HeroTofu forms
      if (response.status === 422) {
        throw new Error("Are you robot?");
      }

      if (response.status !== 200) {
        throw new Error(`${response.statusText} (${response.status})`);
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
