import React from "react";
import IconButton from "@mui/material/IconButton";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

function CloseButton({ onClick, left, right, top, backgroundColor }) {
  return (
    <IconButton
      aria-label="close"
      style={{
        left: left ? left : right ? "auto" : 24,
        right: right ? right : "auto",
        top: top ? top : 24,
        position: "absolute",
        zIndex: 11,
        color: "white",
        backgroundColor: backgroundColor
          ? backgroundColor
          : "rgba(0, 0, 0, 0.5)",
      }}
      onClick={onClick}
    >
      <CloseRoundedIcon />
    </IconButton>
  );
}

export default CloseButton;
