import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import NavBarMobile from "./NavBarMobile";
import NavbarPC from "./NavbarPC";

import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../api/xoicom.reducer";
import { Link, useLocation } from "react-router-dom";

import "../../style/NavBar.css";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../../utils/responsiveUtils";

function NavBar({ language }) {
  const mode = useSelector((state) => state.reducer.navbarMode);
  const navbarText = useSelector((state) => state.reducer.navbarText);

  const pages = Object.keys(navbarText.pages).map((key) => ({
    name: key,
    displayName: navbarText.pages[key],
  }));
  const [activePage, setActivePage] = useState();
  const [navbar, setNavBar] = useState(false);
  const [oldScroll, setOldScroll] = useState(0);
  const [display, setDisplay] = useState("block");
  const changeNavBar = () => {
    if (window.scrollY > 0) {
      //check if scrolling up or down
      setOldScroll(window.scrollY);
      if (oldScroll < window.scrollY) {
        setNavBar(false);
        setDisplay("none");
      } else {
        setNavBar(true);
        setDisplay("block");
      }
    } else {
      setNavBar(false);
      setDisplay("block");
    }
  };
  window.addEventListener("scroll", changeNavBar);

  const location = useLocation();

  const setDefaultActivePage = () => {
    const path = location.pathname.replace("/", "");
    setActivePage(path);
  };

  useEffect(() => {
    setDefaultActivePage();
  }, [location.pathname]);

  useEffect(() => {
    changeLanguage(language);
  }, []);

  const dispatch = useDispatch();
  const changeLanguage = (language) => {
    dispatch(setLanguage(language));
  };

  const mobile = useMediaQuery(breakpoint.mobileSm);
  return (
    <Box
      className={`navbar ${
        navbar ? "navbar-scroll" : `navbar-default display-navbar-${display}`
      }`}
    >
      {mobile ? (
        <div style={{ margin: "8px 24px", display: "flex" }}>
          {/* {logo} */}
          <XoiComLogo navbar={navbar} mode={mode} />
          <NavBarMobile
            pages={pages}
            activePage={activePage}
            language={language}
            changeLanguage={changeLanguage}
            color={navbar ? "black" : mode === "light" ? "white" : "black"}
            text={navbarText}
          />
        </div>
      ) : (
        <NavbarPC
          logo={<XoiComLogo navbar={navbar} mode={mode} />}
          pages={pages}
          activePage={activePage}
          language={language}
          changeLanguage={changeLanguage}
          color={navbar ? "black" : mode === "light" ? "white" : "black"}
          navbar={navbar}
          mode={mode}
        />
      )}
    </Box>
  );
}

function XoiComLogo({ navbar, mode }) {
  const lightLogo = "header-logo-light";
  const darkLogo = "header-logo-dark";
  const logoClass = navbar ? darkLogo : mode === "light" ? lightLogo : darkLogo;

  return (
    <div className="navbar-logo-container">
      <Link
        className={`header-logo-drawer clickable ${logoClass}`}
        to="home"
        alt="xoi-com-logo"
      ></Link>
    </div>
  );
}

export default NavBar;
