import React from "react";
import { motion } from "framer-motion";
import { slideUp } from "../../utils/animationUtils";
import LazyImage from "../generics/LazyImage";

function PieceImage(props) {
  const { containterClass = "", className = "", imageSrc } = props;
  return (
    <motion.div
      className={containterClass}
      variants={slideUp}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
    >
      <LazyImage className={className} imageSrc={imageSrc} alt="" />
    </motion.div>
  );
}

export default PieceImage;
