import React, { useEffect } from "react";

import "../style/About.css";
import "../style/Session.css";

import { useDispatch, useSelector } from "react-redux";
import { setNavBarMode } from "../api/xoicom.reducer";
import { SPICE_CARDS } from "../utils/aboutUtils";
import { GRAPHICS, IMAGES, hasData, scrollToTop } from "../utils/utils";
import { fetchHistoryArticles } from "../api/history/history.reduxThunk";
import { motion } from "framer-motion";
import { fetchAboutNumber } from "../api/reduxThunk";
import SlideWrapper from "../components/generics/SlideWrapper";
import { slideUp } from "../utils/animationUtils";
import OriginText from "../components/about/OriginText";
import XoiComHeading from "../components/generics/XoiComHeading";
import ProcessSession from "../components/about/ProcessSession";
import GreenSession from "../components/about/GreenSession";

const { ABOUT_LANDING, MEMORY_IMG } = IMAGES;

function SlideUp({ children, className }) {
  return (
    <motion.div
      className={className}
      variants={slideUp}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
}

function About() {
  const dispatch = useDispatch();
  const aboutText = useSelector((state) => state.reducer.aboutText);
  const language = useSelector((state) => state.reducer.language);
  const { landingText, memory, origin, process, green } = aboutText;

  const historyCards = useSelector((state) => state.reducer.history);
  const hasHistory = hasData(historyCards);

  useEffect(() => {
    dispatch(fetchHistoryArticles());
    dispatch(fetchAboutNumber());
  }, [language]);

  useEffect(() => {
    scrollToTop();
    dispatch(setNavBarMode("light"));
  }, []);

  return (
    <div>
      <div className="landing-session">
        <img
          className="landing-image"
          src={ABOUT_LANDING}
          alt="about-landing"
        />
        <SlideUp className="landing-text">
          <XoiComHeading heading={landingText} textColor="white" bigHeader />
        </SlideUp>
      </div>
      <div className="about-spice-session">
        <SlideUp className="spice-slide-container">
          <SlideWrapper steps={SPICE_CARDS} mode="spice" />
        </SlideUp>
        <SlideUp className="about-memory">
          <div className="memory-text">
            <div className="cap-r15">{memory.heading}</div>
            <h2>{memory.bodyText}</h2>
          </div>
          <img className="memory-img" src={MEMORY_IMG} alt="memory" />{" "}
        </SlideUp>
      </div>
      <div style={{ position: "relative" }}>
        <div className="about-origin-session">
          <OriginText content={origin} />
          <img
            className="about-origin-logo"
            src={GRAPHICS.HEADER_LOGO_WHITE}
            alt="xoi-com-logo"
          />
        </div>
      </div>
      {hasHistory && (
        <div className="about-journey-session">
          <SlideWrapper steps={historyCards} mode="history" />
        </div>
      )}
      <ProcessSession language={language} heading={process} />
      <GreenSession language={language} heading={green} />
    </div>
  );
}

export default About;
