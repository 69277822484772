import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "../../style/Slide.css";
import "../../style/SpiceSlide.css";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../../utils/responsiveUtils";
import SpiceController from "./SpiceController";
import SpiceContent from "./SpiceContent";
import {
  SPICE_TEXT_GROUPS,
  SPICE_TEXT_GROUPS_MB,
} from "../../utils/aboutUtils";

const getLabel = (steps, language) => {
  return steps.map((step) => step.name[language]);
};

const getDesc = (steps, language) => {
  return steps.map((step) => step.description[language]);
};

function SpiceSlide(props) {
  const { steps, language, activeStep, animation } = props;
  //multilanguage
  const [label, setLabel] = useState(getLabel(steps, language));
  const [description, setDescription] = useState(getDesc(steps, language));
  const mobile = useMediaQuery(breakpoint.mobile);

  useEffect(() => {
    setLabel(getLabel(steps, language));
    setDescription(getDesc(steps, language));
  }, [language]);

  return (
    <Box style={{ display: "flex" }}>
      <Box className="spice-container">
        <div className="spice-controller">
          <div className="cap-r15">5-ELEMENT philosophy</div>
          {mobile ? (
            <h1 className="spice-controller-text">
              <SpiceController
                groups={SPICE_TEXT_GROUPS_MB}
                label={label}
                {...props}
              />
            </h1>
          ) : (
            <div className="header-text spice-controller-text">
              <SpiceController
                groups={SPICE_TEXT_GROUPS}
                label={label}
                {...props}
              />
            </div>
          )}
        </div>
        {steps.map(
          (item, index) =>
            index === activeStep && (
              <SpiceContent
                key={item.id}
                item={item}
                animation={animation}
                description={description[index]}
              />
            )
        )}
      </Box>
    </Box>
  );
}

export default SpiceSlide;
