export const TEXT_VN = {
  community: {
    heading: "Nét::thân thương, mộc mạc::cùng dấu ấn thời gian",
    bodyText:
      "Xới Cơm nằm tại tầng trệt của một khu tập thể trong con ngõ số 36 phố Láng Hạ. Đây là một trong những khu tập thể được xây muộn nhất tại Hà Nội, vào khoảng cuối những năm 90 thế kỷ trước.",
  },
  outside: {
    heading: "Không gian bên ngoài",
    bodyText:
      "Những mảng tường vàng loang lổ, tróc sơn. Cửa sổ xanh thân quen, ban công treo đầy quần áo sắc màu. Bảng tin nằm ngay dưới chân cầu thang còn in vài nét phấn viết tay.",
  },
  nepSong: {
    nepSongHeading: "Nếp sống “::có nhau::”",
    heading: "Nhịp sống thường ngày",
    bodyText:
      "Không gian dù đã nhuốm màu thời gian nhưng nhịp sống nơi đây lại chưa bao giờ buồn tẻ.",
  },
  nature: {
    landing: {
      heading: "Được ôm trọn bởi vòng tay thiên nhiên",
      bodyText:
        "Con ngõ 36 Láng Hạ từ đầu tới cuối thành lối bởi cây xanh. Ở đây, mọi người chăm chút cây như một thành viên trong gia đình.",
    },
    content: [
      {
        heading: "Hàng cau xanh cao quá ba tầng lầu",
        bodyText:
          "Bên hông nhà, hàng cau xanh mơn mởn được chính tay chủ nhà vun trồng tới nay đã cao quá ba tầng lầu. Mỗi dịp tháng 4, tháng 5 dương lịch, hoa cau rụng nhuộm trắng ngần cả một khoảng sân.",
      },
      {
        heading: "Cây sử quân tử ở hiên nhà",
        bodyText:
          "Giàn sử quân tử mang trên mình hàng ngàn ngôi sao rực rỡ, hương hoa theo gió len lỏi khắp không gian. Đứng dưới tán cây, nhìn lên bầu trời và ngắm những giọt nắng rơi xuống qua kẽ lá. Bình yên, khi ấy, nằm gọn trong tầm mắt.",
      },
    ],
  },
  piece: {
    headingText: "::Tâm ý::gửi vào từng mảnh ghép",
    content: [
      {
        heading: "Những món đồ cổ tận tay sưu tầm",
        bodyText:
          "Bàn ghế tại Xới Cơm đều là những món đồ cổ được chúng tôi tận tay sưu tầm tại các vùng khác nhau từ Đà Nẵng trở vào. Mỗi cái bàn, chiếc ghế đều đã từng có đời sống riêng và giờ chúng tập hợp ở đây, cùng Xới Cơm viết tiếp những câu chuyện.",
      },
      {
        heading: "Gạch bông lát sàn ấm cúng",
        bodyText:
          "Sàn nhà được lát gạch bông in hoa văn mềm mại đầy tinh tế mang lại cho không gian Xới Cơm vẻ ấm cúng, thân thuộc.",
      },
      {
        heading: "Bức tường gạch gió quen thuộc",
        bodyText:
          "Thường được lát ở các khu vực cầu thang nhà tập thể hay chung cư cũ, bức tường gạch gió ở Xới Cơm được sử dụng để ngăn không gian nhưng vẫn lấy được ánh sáng tự nhiên.",
      },
    ],
  },
};

export const TEXT_EN = {
  community: {
    heading: "::Endearing Simplicity::Etched with the Passage of Years",
    bodyText:
      "Xới Cơm is nestled on the ground floor of a tenement house in a a small alleyway at number 36 Lang Ha street. This is among the latest to be constructed in Hanoi during the late 1990s.",
  },
  outside: {
    heading: "Outdoor Space",
    bodyText:
      "The walls were a patchwork of yellow, peeling paint. The familiar green windows were framed by balconies overflowing with colorful laundry. A bulletin board, still remaining traces of handwritten chalk, rested at the base of the stairs.",
  },
  nepSong: {
    nepSongHeading: "The lifestyle of::“having each other ”",
    heading: "The Rhythm of Routine",
    bodyText:
      "The years may have passed, but the pulse of life here never falters.",
  },
  nature: {
    landing: {
      heading: "Embraced by the bosom of nature",
      bodyText:
        "Lane 36 Lang Ha Street is lined with trees from end to end. Here, people care for the trees like members of their family.",
    },
    content: [
      {
        heading: "Three-story Areca Palm",
        bodyText:
          "Flanking the side of the house, a row of lush green areca  was cultivated by the owner, now tower over three stories tall. Every April and May, the areca blossoms fall, painting the courtyard with a pristine white.",
      },
      {
        heading: "The Rangoon creeper on the porch",
        bodyText:
          "A trellis of Rangoon creeper bursting with a thousand dazzling blooms, their sweet scent carried by the wind, wafting through the air. Standing under the leafy canopy, gazing up at the sky, watching the sunbeams dance through the gaps in the leaves. Peace and quiet, right there in front of your eyes.",
      },
    ],
  },
  piece: {
    headingText: "::Heartfelt emotions::poured into every piece",
    content: [
      {
        heading: "Antique treasures meticulously collected",
        bodyText:
          "At Xới Cơm, each table and chair is an antique, meticulously handpicked from various regions, from Da Nang to the south. Each piece has its own story, and now they're all together at Xới Cơm, creating new memories with us.",
      },
      {
        heading: "Homey Vietnamese encaustic cement tiles",
        bodyText:
          "The floor is tiled with delicate encaustic cement tiles, bringing a warm and familiar ambiance to the dining space.",
      },
      {
        heading: "The familiar concrete breeze block wall",
        bodyText:
          "Commonly installed in the stairwell areas of old tenement houses or condominiums, the concrete breeze block wall at Xới Cơm is used to partition the space while still allowing natural light to filter through.",
      },
    ],
  },
};

export const SPACE_IMAGES = {
  TREO_QUAN_AO: "assets/Space/treoquanao.png",
  SPACE_OUTSIDE: "assets/Space/Space1.png",
  BLACKBOARD: "/assets/Space/blackboard.png",
  CHOICO: "/assets/Space/2cuchoico.png",
  NEP_SONG_1: "/assets/Space/Space2.png",
  NEP_SONG_2: "/assets/Space/Space4.png",
  NEP_SONG_3: "/assets/Space/Space3.png",
  NATURE_IMAGE_1: "/assets/Space/Space5.png",
  NATURE_IMAGE_2: "/assets/Space/Space6.png",
  MAIN_IMAGE: "/assets/Space/front.png",
  PIECE_1: "/assets/Space/Piece/Piece1.png",
  PIECE_2: "/assets/Space/Piece/Piece2.png",
  PIECE_3: "/assets/Space/Piece/Piece3.svg",
  PIECE_4: "/assets/Space/Piece/Piece6.svg",
  PIECE_5: "/assets/Space/Piece/Piece6.svg",
  PIECE_6: "/assets/Space/Piece/Piece6.svg",
};

export const SPACE_PARALLAX = [
  {
    id: 0,
    title: "",
    image: SPACE_IMAGES.SPACE_OUTSIDE,
  },
  {
    id: 1,
    title: "",
    image: SPACE_IMAGES.NEP_SONG_1,
  },
  {
    id: 2,
    title: "",
    image: SPACE_IMAGES.NEP_SONG_2,
  },
  {
    id: 3,
    title: "",
    image: SPACE_IMAGES.NEP_SONG_3,
  },
  {
    id: 4,
    title: "",
    image: SPACE_IMAGES.NATURE_IMAGE_1,
  },
  {
    id: 5,
    title: "",
    image: SPACE_IMAGES.NATURE_IMAGE_2,
  },
];

export const getSliderImage = (image, id) => {
  return { id, alt: "xoi-com-space", image };
};

export const getParallaxImage = (parallax, image) => {
  return image ? { ...parallax, image } : parallax;
};
