import React from "react";
import "../../style/Slide.css";
import "../../style/components/BlogSlide.css";
import { motion } from "framer-motion";
import Swipe from "../generics/Swipe";

function PressReviewSwipe({ steps, activeStep, handleStep, stepper }) {
  const content = steps.map((step, index) => (
    <div key={index} className="swipe-review-container">
      <div>{Math.abs(activeStep - index) <= 2 && <Review step={step} />}</div>
    </div>
  ));

  return (
    <div>
      <motion.div className="swipe-container" style={{ width: "100vw" }}>
        <Swipe
          steps={steps}
          activeStep={activeStep}
          handleStep={handleStep}
          autoPlayTrigger={false}
          contentComponent={content}
        />
      </motion.div>
      {steps.length > 1 && (
        <div className="slide-dots-container">{stepper}</div>
      )}
    </div>
  );
}

function Review({ step }) {
  return (
    <div className="body-b2 swipe-press-review-content">
      <h2 className="review-text">{step.description}</h2>
      <div className="home-press-review-logo">
        <img
          style={{ width: "100%" }}
          alt={step.alt || "michelin-guide"}
          src={step.image}
        />
      </div>
    </div>
  );
}

export default PressReviewSwipe;
