import React from "react";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../../utils/responsiveUtils";
import { useScroll, useTransform } from "framer-motion";
import XoiComHeading from "../generics/XoiComHeading";
import TextParagraph from "./TextParagraph";

import { motion } from "framer-motion";
import { slideUp } from "../../utils/animationUtils";
import { SPACE_IMAGES } from "../../utils/spaceUtils";

const { BLACKBOARD, CHOICO } = SPACE_IMAGES;

function NepSongSession({ content, target, images, handleOpenSlide }) {
  const mobile = useMediaQuery(breakpoint.mobile);
  const { scrollYProgress: scrollYProgressParallax } = useScroll({
    target,
    offset: ["start end", "end start"],
  });

  const layer1 = useTransform(
    scrollYProgressParallax,
    [0, 1],
    [0, mobile ? 70 : 100]
  );

  const layer2 = useTransform(
    scrollYProgressParallax,
    [0, 1],
    [0, mobile ? -70 : -100]
  );

  return (
    <div className="nep-song" ref={target}>
      <div className="nep-song-heading">
        <div style={{ maxWidth: 640, margin: "auto" }}>
          <XoiComHeading
            heading={content.nepSongHeading}
            bigHeader
            textColor="#8c062e"
          />
        </div>
      </div>
      <div className="nep-song-text-container">
        <TextParagraph content={content} body="body-b1" />
      </div>
      <motion.div
        className="nep-song-image-container"
        variants={slideUp}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
      >
        <img className="blackboard" src={BLACKBOARD} alt="blackboard" />
        <img className="choi-co" src={CHOICO} alt="choi-co" />
      </motion.div>
      {images && (
        <div>
          {images.slice(1, 4).map((graphic, index) => (
            <motion.img
              key={graphic.id}
              className={`nep-song-${index + 1} clickable`}
              src={graphic.image}
              alt=""
              variants={slideUp}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
              style={{ y: index % 2 === 0 ? layer1 : layer2 }}
              onClick={() => handleOpenSlide(index + 1)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default NepSongSession;
