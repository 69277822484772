import React from "react";

function LanguageButton({
  language,
  active,
  changeLanguage,
  color = "",
  mobile,
}) {
  const drawerButtonClass = mobile ? "custom-button-language-drawer" : "";
  const drawerButtonDisabledClass = mobile
    ? "custom-button-language-drawer-disable"
    : "";

  const buttonClass = active
    ? `custom-button-language ${color}`
    : `custom-button-language-disabled ${drawerButtonDisabledClass}`;

  return (
    <button
      className={`${drawerButtonClass} ${buttonClass}`}
      onClick={() => changeLanguage(language)}
    >
      {language.toUpperCase()}
    </button>
  );
}

export default LanguageButton;
