import React, { useRef } from "react";
import { SPACE_IMAGES } from "../../utils/spaceUtils";
import "../../style/Space.css";
import { useScroll, useTransform } from "framer-motion";
import { motion } from "framer-motion";
import { easeInOut } from "framer-motion";

import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../../utils/responsiveUtils";

function ZoomImage() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });
  const opacity = useTransform(scrollYProgress, [0.75, 1], [1, 0], {
    ease: easeInOut,
  });
  const scale = useTransform(scrollYProgress, [0.5, 1], [1, 2], {
    ease: easeInOut,
  });

  const mobile = useMediaQuery(breakpoint.mobile);
  const large = useMediaQuery(breakpoint.large);

  return (
    <motion.div
      style={{
        height: mobile ? 200 : large ? 600 : 400,
        position: "relative",
      }}
    >
      <motion.div
        ref={ref}
        className="main-image-container"
        style={{ width: "160vw", opacity: opacity, scale: scale }}
      >
        <img
          className="space-natural-main-image"
          src={SPACE_IMAGES.MAIN_IMAGE}
          alt="zoom-house"
        />
      </motion.div>
    </motion.div>
  );
}

export default ZoomImage;
