import { imageWithDefault } from "./utils";

//text in session = heading + body text
export const TEXT_VN = {
  HOME_PHILOSOPHY: {
    HEADING: "Cơm ngon ấm áp, mệt mỏi xua tan!",
    BUTTON: "Triết lý của chúng tôi",
  },
  HOME_MOI_COM_HEADING: "Mời cả nhà ăn cơm!",
  HOME_BLOG_LIST_HEADING: "Cơm ngon nhờ có::bí quyết",
  HOME_PRESS_REVIEW_HEADING: "Báo chí nói về Xới Cơm",
  HOME_RESERVATION: "Liên hệ đặt bàn",
  INGREDIENT_PARALLAX: {
    heading:
      "Nguyên liệu tươi sạch được Xới Cơm tuyển chọn mỗi ngày để bữa cơm ngon, lành và bổ dưỡng",
    button: "Nguồn gốc nguyên liệu",
  },
  letter: {
    heading: "Bạn thương mến,",
    bodyText:
      "<div>Xới Cơm là một nhóm những người trẻ gặp gỡ và gắn kết nhờ có chung tình yêu dành cho bữa cơm nhà. Chúng tôi hiểu rằng, được ăn những món ăn thân thuộc luôn đem lại cảm giác thoải mái, dễ chịu mà ta không tìm được ở đâu khác.<br />Với tinh thần đó, Xới Cơm ra đời nhằm tạo ra một không gian để bạn ghé dùng bữa và thả lỏng sau một ngày làm việc vất vả.<br />Hy vọng rằng, bạn luôn cảm thấy thoải mái mỗi lần tới với Xới Cơm.</div>",
  },
};

export const TEXT_EN = {
  HOME_PHILOSOPHY: {
    HEADING: "Hearty rice warms, fatigue disbands!",
    BUTTON: "Our philosophy",
  },
  HOME_MOI_COM_HEADING: "Let's dig in!",
  HOME_BLOG_LIST_HEADING: "Our::secrets::for a delicious meal",
  HOME_PRESS_REVIEW_HEADING: "Press review",
  HOME_RESERVATION: "Reservation hotline",
  INGREDIENT_PARALLAX: {
    heading:
      "Carefully selected fresh and clean ingredients every early morning to bring you the most nutritious and delicious meals.",
    button: "The Origin of Our Ingredients",
  },
  letter: {
    heading: "Dear customer,",
    bodyText:
      "<div>Enjoying familiar dishes always brings a sense of warm peace and indescribable happiness.<br />Stemming from this passion, Xới Cơm's mission is to create a cozy and familiar space where you can put aside all your worries and troubles to enjoy fresh meals and relax your mind.<br />May you always experience a sense of comfort and ease whenever you visit Xới Cơm.</div>",
  },
};

//text for food slide graphic
export const FOOD_IMAGES = [
  {
    title: "com",
    label: { vn: "1. cơm", en: "1. rice" },
    heading: {
      vn: "Ăn miếng::cơm::cho chắc dạ",
      en: "Get some::rice::to fill the stomach",
    },
    zIndex: "1",
    left: 70,
    top: -25,
    size: { width: 408, height: 293 },
    image: "com",
    imageSrc: "/assets/Home/FoodSlide/com.png",
    description: {
      vn: "Cơm nhà thì sao có thể thiếu một nồi cơm xới chung, vừa ấm bụng, vừa đượm tình. Khi xới cơm, nhớ xới phần cơm dẻo, chín đều ở giữa nồi. Đây chính là cách thể hiện sự quan tâm với những người thân thương.",
      en: "You know a proper Vietnamese home meal is when we all gather around a pot of steamed rice that warms our bellies and strengthens family bonds. The soft and evenly cooked portion from the middle is always served to others first. That's the way we show love!",
    },
  },
  {
    title: "thit",
    label: { vn: "2. món mặn", en: "2. protein dishes" },
    heading: {
      vn: "Gắp miếng::đạm::cho đủ chất",
      en: "Take a bite of::protein dishes::for all the nutrients needed",
    },
    zIndex: "2",
    left: 430,
    top: 110,
    size: { width: 337, height: 161 },
    image: "thit",
    imageSrc: "/assets/Home/FoodSlide/thit.png",
    description: {
      vn: "Cảm nhận độ tươi, ngọt tự nhiên của các món đạm nhờ các nguyên liệu được đi chợ mua mới mỗi ngày. Món đạm đậm vị ăn với cơm trắng dẻo thơm, nóng hổi thì không còn gì sánh bằng.",
      en: "The protein rich dishes taste so savoury yet flavourful because all the ingredients are freshly brought daily from local market. When you have them with the warm, yummy steamed rice, they become the most delicious thing ever!",
    },
  },
  {
    title: "rau",
    label: { vn: "3. món rau", en: "3. vegetable" },
    heading: {
      vn: "Thêm::rau::cho mát ruột",
      en: "Don't forget some::vegetable::for a balance diet",
    },
    zIndex: "3",
    left: 442,
    top: 230,
    size: { width: 399, height: 243 },
    image: "rau",
    imageSrc: "/assets/Home/FoodSlide/rau.png",
    description: {
      vn: "Giữ đúng giá trị “mùa nào thức nấy”, rau củ vào mùa đặc biệt tươi sạch và bổ dưỡng. Nhớ gọi đĩa rau luộc ăn kèm với món đạm. Và ngược lại, cân bằng hương vị món đạm nhạt với một đĩa rau xào.",
      en: "Eating seasonal vegetables ensures they are fresh, clean and nutritious while also balances our meal. Pair your protein rich dish with boiled vegetables and balance milder flavors with stir-fried ones.",
    },
  },
  {
    title: "canh",
    label: { vn: "4. canh", en: "4. soup" },
    heading: {
      vn: "Chan muôi::canh::cho đưa cơm",
      en: "Sip some::soup::to increase your appetite",
    },
    zIndex: "5",
    left: 92,
    top: 276,
    size: { width: 420, height: 249 },
    image: "canh",
    imageSrc: "/assets/Home/FoodSlide/canh.png",
    description: {
      vn: "Cơm nhà dù có giản dị thế nào cũng không thể trọn vẹn nếu thiếu một tô canh thơm ngon. Dù là canh rau luộc đơn giản hay canh cá, canh xương thì cũng đều giúp gia tăng khẩu vị của bữa cơm nhà.",
      en: "Having a bowl of savoury soup alongside cooked rice helps replenish fluils and balances the flavours of the main dishes to perfectly suit your taste.",
    },
  },
  {
    title: "mon-an-kem",
    label: { vn: "5. món ăn kèm", en: "5. side dishes" },
    heading: {
      vn: "::Ăn kèm::cho tròn vị",
      en: "Get extra flavours from these::side dishes",
    },
    zIndex: "4",
    left: 11,
    top: 190,
    size: { width: 281, height: 171 },
    image: "mon-an-kem",
    imageSrc: "/assets/Home/FoodSlide/ca-muoi.png",
    description: {
      vn: "Những món ăn kèm chua mát, cắn miếng giòn tan, chấm với nước mắm thêm phần đậm đà là cách để tạo điểm nhấn và làm tăng hương vị cho các món chính.",
      en: "Finally, having at least one tangy and crunchy side dish, dipped in flavourful fish sauce to accentuate and enhance the flavour of the main dishes.",
    },
  },
  {
    title: "nuoc-mam",
    label: "",
    heading: "",
    zIndex: "4",
    left: 315,
    top: 196,
    size: { width: 175, height: 109 },
    image: "nuoc-mam",
    imageSrc: "/assets/Home/FoodSlide/nuoc-mam.png",
    description: "",
  },
];

export const INGREDIENT_PARALLAX = [
  {
    title: "parallax-1",
    image: "/assets/Home/Parallax/Ingredient1.png",
    default: {
      size: { width: 230, height: 300 },
      position: { left: 88, top: 189 },
    },
    mobile: {
      size: { width: 100, height: 300 },
      position: { left: -33, top: 100 },
    },
  },
  {
    title: "parallax-2",
    image: "/assets/Home/Parallax/Ingredient2.png",
    default: {
      size: { width: 278, height: 200 },
      position: { left: 412, top: 40 },
    },
    mobile: {
      size: { width: 132, height: 200 },
      position: { left: 93, top: 0 },
    },
  },
  {
    title: "parallax-3",
    image: "/assets/Home/Parallax/Ingredient3.png",
    default: {
      size: { width: 280, height: 300 },
      position: { left: 934, top: 258 },
    },
    mobile: {
      size: { width: 132, height: 300 },
      position: { left: 313, top: 121 },
    },
  },
  {
    title: "parallax-4",
    image: "/assets/Home/Parallax/Ingredient4.png",
    default: {
      size: { width: 230, height: 240 },
      position: { left: 1144, top: 155 },
    },
    mobile: {
      size: { width: 132, height: 240 },
      position: { left: -64, top: 437 },
    },
  },
  {
    title: "parallax-5",
    image: "/assets/Home/Parallax/Ingredient5.png",
    default: {
      size: { width: 278, height: 200 },
      position: { left: 0, top: 651 },
    },
    mobile: {
      size: { width: 100, height: 200 },
      position: { left: 27, top: 567 },
    },
  },
  {
    title: "parallax-6",
    image: "/assets/Home/Parallax/Ingredient6.png",
    default: {
      size: { width: 230, height: 240 },
      position: { left: 233, top: 900 },
    },
    mobile: {
      size: { width: 132, height: 240 },
      position: { left: 196, top: 615 },
    },
  },
  {
    title: "parallax-7",
    image: "/assets/Home/Parallax/Ingredient7.png",
    default: {
      size: { width: 280, height: 200 },
      position: { left: 736, top: 910 },
    },
    mobile: {
      size: { width: 132, height: 200 },
      position: { left: 357, top: 466 },
    },
  },
  {
    title: "parallax-8",
    image: "/assets/Home/Parallax/Ingredient8.png",
    default: {
      size: { width: 230, height: 300 },
      position: { left: 1081, top: 812 },
    },
    mobile: {
      size: { width: 230, height: 300 },
      position: { left: 1081, top: 812 },
    },
  },
];

//reducer methods
export const getIngredientParallaxImage = (parallax, image) => {
  //images are in the same order as specified in the content pages
  //if there are more than 8 images, only use the first 8 images
  //if there are less than 8 images, the missing images will be replaced by default image
  return image ? { ...parallax, image } : parallax;
};

export const getFormattedReview = (review, description) => {
  return {
    description,
    image: imageWithDefault(review.jetpack_featured_media_url, "logo"),
  };
};
