import { useMediaQuery } from "@mui/material";
import React from "react";
import { breakpoint } from "../../utils/responsiveUtils";
import TextParagraph from "./TextParagraph";
import NatureImage from "./NatureImage";
import ZoomImage from "../generics/ZoomImage";

function NatureSession({ background, content, images, handleOpenSlide }) {
  const mobile = useMediaQuery(breakpoint.mobile);
  const { landing, content: contentParagrah } = content;

  return (
    <div className="space-nature-session">
      <div ref={background} className="space-nature-heading-container">
        <div className="space-nature-text">
          <h1>{landing.heading}</h1>
          <div className={mobile ? "body-b2" : "body-b1"}>
            {landing.bodyText}
          </div>
        </div>
      </div>
      <div className="space-natural-content-container">
        <div
          className={`space-natural-content-card ${
            !mobile && "space-natural-card-1"
          }`}
        >
          <div
            className={`space-natural-text-container ${
              !mobile && "space-natural-text-1"
            }`}
          >
            <TextParagraph content={contentParagrah[0]} />
          </div>
          <NatureImage
            imageSrc={images && images[4].image}
            onClick={() => handleOpenSlide(4)}
            className={`space-natural-image${mobile ? "" : "-1"}`}
          />
        </div>
        <div
          className={`space-natural-content-card ${
            !mobile && "space-natural-card-2"
          }`}
        >
          {!mobile && (
            <NatureImage
              imageSrc={images && images[5].image}
              onClick={() => handleOpenSlide(5)}
              className="space-natural-image-2"
            />
          )}
          <div
            className={`space-natural-text-container ${
              !mobile && "space-natural-text-2"
            }`}
          >
            <TextParagraph content={contentParagrah[1]} />
          </div>
          {mobile && (
            <NatureImage
              imageSrc={images && images[5].image}
              onClick={() => handleOpenSlide(5)}
              className="space-natural-image"
            />
          )}
        </div>
      </div>
      <ZoomImage />
    </div>
  );
}

export default NatureSession;
