import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function LazyImage(props) {
  const {
    className,
    mode,
    imgClass = "",
    imageSrc,
    alt,
    height,
    width,
  } = props;
  return (
    <div className={className || "lazy-image"}>
      <LazyLoadImage
        className={imgClass}
        effect="blur"
        src={imageSrc}
        alt={alt}
        height={height || (mode === "mobile" ? "auto" : "100%")}
        width={width || "100%"}
      />
    </div>
  );
}

export default LazyImage;
