import React from "react";
import TextParagraph from "./TextParagraph";
import ImagePopup from "./ImagePopup";

function SpaceOutsideImage({ imageSrc, onClick }) {
  return (
    <img
      className="space-outside-image clickable"
      src={imageSrc}
      alt="space-outside"
      onClick={onClick}
    />
  );
}

function OutsideSession({
  images,
  content,
  openSlide,
  handleOpenSlide,
  handleCloseSlide,
  selectedImage,
}) {
  return (
    <>
      <SpaceOutsideImage
        imageSrc={images && images[0].image}
        onClick={() => handleOpenSlide(0)}
      />
      <div className="space-outside-text-container">
        <TextParagraph
          content={content}
          headingClass="space-outside-heading"
          bodyClass="space-outside-bodytext"
        />
      </div>
      {images && (
        <ImagePopup
          images={images}
          open={openSlide}
          handleClosePopup={handleCloseSlide}
          selected={selectedImage}
        />
      )}
    </>
  );
}

export default OutsideSession;
