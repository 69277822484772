export const slideUp = {
  initial: { opacity: 0, y: "100px" },
  animate: { opacity: 1, y: 0, transition: "all 1s ease-in-out" },
};

export const slideUpDelay = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { delay: 1, duration: 2, ease: "easeInOut" },
  },
};

export const slideUpFromZero = {
  initial: { opacity: 1, y: 0 },
  animate: {
    opacity: 0,
    y: -200,
    transition: { delay: 1, duration: 1, ease: "easeInOut" },
  },
};

export const staggerDisplay = {
  initial: { opacity: 0 },
  animate: (index) => ({
    opacity: 1,
    transition: { delay: 0.5 * index, duration: 1, ease: "easeInOut" },
  }),
};

export const staggerUp = {
  initial: { opacity: 0, y: "100px" },
  animate: (index) => ({
    opacity: 1,
    y: 0,
    transition: { delay: 0.1 * index, duration: 1, ease: "easeInOut" },
  }),
};

export const fadeIn = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
};

export const fadeOut = {
  initial: { opacity: 1 },
  animate: { opacity: 0, transition: { duration: 0.5, ease: "easeInOut" } },
};
