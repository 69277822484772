import React, { useEffect, useState } from "react";
import ImageSwipe from "./ImageSwipe";
import PressReviewSwipe from "../home/PressReviewSwipe";
import StepperDots from "./StepperDots";

function SwipeWrapper({
  steps,
  selected,
  auto,
  mode,
  preview,
  ratio,
  handleClickTitle,
}) {
  const [activeStep, setActiveStep] = useState(0);

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const [autoplay, setAutoplay] = useState(auto);

  useEffect(() => {
    if (activeStep === steps.length - 1) {
      setAutoplay(false);
    }
  }, [activeStep]);

  useEffect(() => {
    if (selected !== undefined) {
      setActiveStep(selected);
    }
  }, [selected]);

  const stepper = (
    <StepperDots
      length={steps.length}
      activeStep={activeStep}
      color={mode === "image-slide" ? "#FFF7EA" : "grey"}
    />
  );

  return (
    <div>
      {mode === "image-slide" ? (
        <ImageSwipe
          steps={steps}
          activeStep={activeStep}
          handleStep={handleStep}
          blogPreview={preview}
          ratio={ratio}
          autoplay={autoplay}
          stepper={stepper}
          handleClickTitle={handleClickTitle}
        />
      ) : (
        <PressReviewSwipe
          steps={steps}
          activeStep={activeStep}
          handleStep={handleStep}
          stepper={stepper}
        />
      )}
    </div>
  );
}

export default SwipeWrapper;
