import {
  SPACE_TEXT,
  SPACE_SLIDE,
  SPACE_PARALLAX,
  fetchByCategory,
} from "../utils.api";

//get space images
export const spaceImagesApi = async () => {
  const [images] = await fetchByCategory(SPACE_SLIDE);
  const [parallax] = await fetchByCategory(SPACE_PARALLAX);

  //get the first post that match the params
  return { images, parallax };
};

//get space text
export const spaceTextApi = async () => {
  const data = await fetchByCategory(SPACE_TEXT);

  //get the first post that match the params
  return data[0];
};
