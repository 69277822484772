import React from "react";
import XoiComHeading from "../generics/XoiComHeading";
import { IMAGES } from "../../utils/utils";
import Box from "@mui/material/Box";
import Counter from "../generics/Counter";
import { useSelector } from "react-redux";

function GreenSession({ language, heading }) {
  const numberCards = useSelector((state) => state.reducer.aboutNumber);

  return (
    <div className="about-green-session">
      <img
        className="background-image"
        src={IMAGES.GREEN_BG}
        alt="about-green"
      />
      <Box className="about-green-heading-container">
        <div xs={4} className="about-green-heading-container-inner">
          <div
            className="about-green-heading"
            style={{ maxWidth: language === "en" && 350 }}
          >
            <XoiComHeading heading={heading} textColor="white" />
          </div>
        </div>
        {numberCards && (
          <div xs={8} className="about-green-number-card-container">
            {numberCards.map((card, index) => (
              <div
                key={`card-number-${index}`}
                className="about-green-number-card"
              >
                <Counter end={card.number} duration={10} />
                <div className="about-green-number-text">
                  {card.description}
                </div>
              </div>
            ))}
          </div>
        )}
      </Box>
    </div>
  );
}

export default GreenSession;
