import React from "react";
import "../../style/About.css";

function OriginText({ content }) {
  return (
    <h2 className="about-origin-text">
      {content.map((c, index) => (
        <span
          key={`origin-${index}`}
          className={`${c.type === "accent" && "header-text-accent"}`}
        >
          {c.text}{" "}
        </span>
      ))}
    </h2>
  );
}

export default OriginText;
