import React from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";

import "../style/Footer.css";
import { useSelector } from "react-redux";
import { GRAPHICS, LINK } from "../utils/utils";
import { useMediaQuery } from "@mui/material";
import { breakpoint } from "../utils/responsiveUtils";
import SVGIcon from "./generics/SVGIcon";

function Footer() {
  const footerText = useSelector((state) => state.reducer.footerText);
  const mobile = useMediaQuery(breakpoint.mobile);
  const small = useMediaQuery(breakpoint.small);
  const medium = useMediaQuery(breakpoint.medium);

  return (
    <div className={mobile ? "mobile-footer-container" : ""}>
      <Divider />
      <div
        className={
          mobile ? "mobile-footer-grid-container" : "footer-grid-container"
        }
      >
        <Grid
          style={{
            flexDirection: mobile ? "column" : "row",
            alignItems: mobile ? "center" : "flex-start",
            padding: mobile ? 40 : 0,
            margin: "auto",
          }}
          className="footer-grid"
          wrap="nowrap"
          container
          spacing={0}
          col="true"
        >
          <Grid className="footer-logo-grid" columns={{ xs: 3, sm: 2, md: 3 }}>
            <FooterLogo text={footerText} mobile={mobile} />
          </Grid>
          <Grid
            className="footer-main-content-grid"
            columns={{ xs: 6, sm: 8, md: 6 }}
          >
            <FooterContact text={footerText} />
            {small && !medium && <FooterSocialShare text={footerText} />}
            {!mobile && (
              <div className="xoi-com-credit-text">© 2023 Xoi com</div>
            )}
          </Grid>
          {(mobile || medium) && (
            <Grid
              className="footer-contact-grid"
              columns={{ xs: 3, sm: 2, md: 3 }}
            >
              <FooterSocialShare text={footerText} />
            </Grid>
          )}
        </Grid>
      </div>
      <Divider className={!mobile ? "divider" : ""} />
      {mobile && (
        <div
          className="mobile-footer-grid-container"
          style={{ margin: "0 40px 24px" }}
        >
          <Credit text={footerText} />
          <div className="xoi-com-credit-text">© 2023 Xoi com</div>
        </div>
      )}
    </div>
  );
}
function FooterLogo({ text, mobile }) {
  return (
    <>
      <div className="footer-logo-container">
        <img
          alt="xoi-com-footer-logo"
          className={`xoi-com-footer-logo${mobile ? "-mobile" : ""}`}
          src={GRAPHICS.FOOTER_LOGO}
        />
      </div>
      {!mobile && (
        <Credit text={text} style={{ position: "absolute", bottom: 0 }} />
      )}
    </>
  );
}

function FooterContact({ text }) {
  return (
    <div className="address">
      <div>{text.address}</div>
      <div>{text.phone}</div>
      <div>{text.hour}</div>
    </div>
  );
}

function FooterSocialShare({ text }) {
  return (
    <div className="contact-us">
      <div className="contact-us-label">{text.contactUs}</div>
      <div className="contact-us-logo">
        {["facebook", "instagram"].map((icon, index) => (
          <button key={icon} className="custom-icon-button">
            <SVGIcon icon={icon} color="#9A9A9A" />
          </button>
        ))}
      </div>
    </div>
  );
}

function Credit({ text, style }) {
  return (
    <div className="xoi-com-credit-text" style={style}>
      {text.creditText}{" "}
      <a className="xoi-com-credit-link" href={LINK.MGC_INSTA}>
        MGC.CLB
      </a>
    </div>
  );
}

export default Footer;
