import React from "react";

function SpiceController(props) {
  const { steps, groups } = props;
  return (
    <div className="spice-controller-group">
      {groups.map(([start, end], index) => (
        <div key={`spice-controller-${index}`}>
          <SpiceControllerGroup
            steps={steps}
            start={start}
            end={end}
            {...props}
          />
        </div>
      ))}
    </div>
  );
}

function SpiceControllerGroup(props) {
  const { steps, start, end } = props;
  const selectedSpices = steps.slice(start, end);

  return selectedSpices.map((item, index) => (
    <SpiceControllerText key={item.id} index={index + start} {...props} />
  ));
}

function SpiceControllerText({
  steps,
  language,
  activeStep,
  clicked,
  toBeClicked,
  handleStep,
  index,
  label,
}) {
  const andText = { vn: "và ", en: "and " };
  const activeClass =
    index === activeStep ? "header-text-accent line-height-12" : "";
  const clickClass =
    !clicked[index] && toBeClicked[index] ? "underline-animate" : "";
  const step = index < steps.length - 1;
  const lastStep = index === steps.length - 1;

  return (
    <span>
      <span>{lastStep && andText[language]}</span>
      <span
        className={`clickable ${activeClass} ${clickClass}`}
        onClick={handleStep(index)}
      >
        {label[index]}
      </span>
      <span>{step && ","}&nbsp;</span>
    </span>
  );
}

export default SpiceController;
