import { LINK } from "./utils";

export const TEXT_VN = {
  creditText: "Website by",
  address: "Số 36, Láng Hạ, Đống Đa, Hà Nội",
  phone: "Số điện thoại: 0866810736",
  hour: "10:30 - 14:00 | 17:30 - 21:00",
  contactUs: "Kết nối với chúng tôi:",
};

export const TEXT_EN = {
  creditText: "Website by",
  address: "No.36, Lang Ha Street, Dong Da Distric, Hanoi",
  phone: "Hotline: 0866810736",
  hour: "10:30 - 14:00 | 17:30 - 21:00",
  contactUs: "Contact us:",
};

export const FOOTER_CARDS = [
  {
    id: 0,
    text: {
      vn: "Xem thực đơn đổi món hàng ngày của chúng tôi",
      en: "View our daily changing menu",
    },
    image: "/assets/Contact/Footer1.png",
    alt: "menu",
    link: LINK.MENU,
  },
  {
    id: 1,
    text: {
      vn: "Tìm chỉ đường và hướng dẫn chỗ gửi xe oto đến Xới Cơm",
      en: "Find directions and parking instructions to Xới Cơm",
    },
    image: "/assets/Contact/Footer2.png",
    alt: "direction",
    link: LINK.MAP,
  },
  {
    id: 2,
    text: {
      vn: "Tìm hiểu về căn nhà tập thể của chúng tôi",
      en: "Learn more about our tenement house",
    },
    alt: "space",
    image: "/assets/Contact/Footer3.png",
  },
];
