import React, { useState } from "react";
import { motion } from "framer-motion";
import NavBarToggle from "./NavBarToggle";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import SVGIcon from "../generics/SVGIcon";
import { Link } from "react-router-dom";
import LanguageButton from "./LanguageButton";
import PageButton from "./PageButton";

const StyledDrawer = styled(SwipeableDrawer)(() => ({
  "& .MuiDrawer-paper": {
    width: "100%",
    backgroundColor: "#660522",
    height: "auto",
    overflow: "visible",
  },
}));

function NavBarMobile(props) {
  const { color, activePage, language, pages, changeLanguage, text } = props;
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <motion.nav initial={false} animate={open ? "open" : "closed"}>
      <NavBarToggle toggle={() => toggleOpen()} color={color} />
      <StyledDrawer
        style={{ width: "100%" }}
        anchor="top"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Box className="drawer-pages" onClick={() => setOpen(false)}>
          <div
            className="navbar-logo-container"
            style={{ justifyContent: "space-between" }}
          >
            <Link
              className="header-logo-drawer clickable header-logo-light"
              alt="xoi-com-logo"
              to={"home"}
            ></Link>
            <div>
              <NavBarToggle toggle={() => toggleOpen()} color="white" />
            </div>
          </div>
          <div className="mobile-pages">
            <Page
              page={{ name: "home", displayName: text.home }}
              active={activePage === "home"}
            />
            {pages.map((page) => (
              <Page
                key={page.name}
                page={page}
                active={activePage === page.name}
              />
            ))}
          </div>
          <Divider
            style={{ width: "100%", borderColor: "rgba(255, 255, 255, 0.10)" }}
          />
          <div className="drawer-container">
            <div className="drawer-language">
              <span className="body-b2">{text.language}</span>
              <span className="button-language-container-mobile">
                <ButtonGroup
                  changeLanguage={changeLanguage}
                  language={language}
                />
              </span>
            </div>
            <div className="drawer-language">
              <span className="body-b2">{text.contactUs}</span>
              <SVGIcon icon="facebook" />
              <SVGIcon icon="instagram" />
            </div>
          </div>
        </Box>
      </StyledDrawer>
    </motion.nav>
  );
}

function Page({ page, active }) {
  const activeClass = active ? "mobile-page-active" : "";
  const pageClass = `white custom-button-navbar-mobile ${activeClass}`;

  return (
    <div
      className="mobile-page page"
      style={{ alignItems: "flex-start", paddingTop: 0 }}
    >
      <PageButton page={page} pageClass={pageClass} />
    </div>
  );
}

function ButtonGroup({ changeLanguage, language }) {
  return (
    <>
      <LanguageButton
        language="en"
        active={language === "en"}
        changeLanguage={changeLanguage}
        mobile
      />
      <button className="custom-button-language-drawer custom-button-language-drawer-disable custom-button-language-disabled non-clickable">
        -
      </button>
      <LanguageButton
        language="vn"
        active={language === "vn"}
        changeLanguage={changeLanguage}
        mobile
      />
    </>
  );
}
export default NavBarMobile;
