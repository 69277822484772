import React from "react";
import Box from "@mui/material/Box";
import "../../style/Slide.css";
import "../../style/components/PressReviewSlide.css";
import { motion } from "framer-motion";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

const ReviewSkeleton = () => {
  return (
    <div
      className="press-review-content-container"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Skeleton variant="text" width="100%" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" width="100%" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" width="100%" sx={{ fontSize: "1rem" }} />
    </div>
  );
};

function PressReviewSlide({
  steps,
  activeStep,
  backButton,
  nextButton,
  animation,
}) {
  const loading = useSelector((state) => state.reducer.loading);
  const animationClass = animation ? "fade-in" : "fade-out";

  return (
    <Box className="slide-container">
      <Box className="text-button-container review-container">
        {steps.length > 1 && backButton}
        {/* content */}
        {loading ? (
          <ReviewSkeleton />
        ) : (
          <div className="press-review-content-container">
            {steps.map(
              (step, index) =>
                index === activeStep && (
                  <ReviewText
                    key={`review-${index}`}
                    step={step}
                    animationClass={animationClass}
                  />
                )
            )}
          </div>
        )}
        {steps.length > 1 && nextButton}
      </Box>
      {loading ? (
        <div className="press-review-logo-container">
          <Skeleton variant="circular" width={64} height={64} />
        </div>
      ) : (
        steps.map(
          (step, index) =>
            index === activeStep && (
              <ReviewLogo
                key={`review-${index}`}
                step={step}
                animationClass={animationClass}
              />
            )
        )
      )}
    </Box>
  );
}

function ReviewText({ step, animationClass }) {
  return (
    <motion.div className={`body-b2 press-review-content ${animationClass}`}>
      <Box style={{ width: "100%" }}>
        <h2>{step.description}</h2>
      </Box>
    </motion.div>
  );
}
function ReviewLogo({ step, animationClass }) {
  const { alt, image } = step;

  return (
    <motion.div
      className={`body-b2 press-review-logo-container ${animationClass}`}
    >
      <div className="home-press-review-logo">
        <img
          style={{ width: "100%" }}
          alt={alt || "michelin-guide"}
          src={image}
        />
      </div>
    </motion.div>
  );
}

export default PressReviewSlide;
