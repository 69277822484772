import React, { useEffect, useRef, useState } from "react";
import "../../style/components/BlogList.css";
import { motion } from "framer-motion";
import BlogCardSkeleton from "../skeleton/BlogCardSkeleton";
import BlogCard from "../blog/BlogCard";

function BlogList({ blogs, loading }) {
  const ref = useRef(null);

  //drag animation
  const blogList = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const { current } = blogList;
    const blogListWidth = current.scrollWidth - current.offsetWidth;
    setWidth(blogListWidth);
  }, []);

  return (
    <div>
      <motion.div
        className="blog-list"
        ref={blogList}
        whileTap={{ cursor: "grabbing" }}
      >
        {loading ? (
          <motion.div className="blog-card-list">
            {[1, 2, 3, 4].map((index) => (
              <BlogCardSkeleton key={index} overflow />
            ))}
          </motion.div>
        ) : (
          <motion.div
            drag="x"
            dragConstraints={{ right: 0, left: -width }}
            className="blog-card-list"
          >
            {blogs?.slice(0, 7).map((blog) => (
              <div ref={ref} key={blog.id} style={{ width: "32vw" }}>
                <BlogCard blog={blog} key={blog.id} />
              </div>
            ))}
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}

export default BlogList;
