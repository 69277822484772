import { hasData } from "../../utils/utils";
import {
  BLOG,
  getPostsByLanguage,
  getMultiLanguagePostMapping,
  fetchTag,
  fetchByCategory,
  fetchByCategoryAndId,
  getTagsFromBlog,
  NEWS_BLOG,
  COOKING_TIPS_BLOG,
  LOCAL_FOOD_BLOG,
} from "../utils.api";

const FEATURE_BLOG = 856294;
const LIMIT = 50;

//get blog articles
const ALL_BLOGS = [
  BLOG,
  NEWS_BLOG,
  COOKING_TIPS_BLOG,
  LOCAL_FOOD_BLOG,
].toString();

export const blogApi = async () => {
  const data = await fetchByCategory(ALL_BLOGS, `per_page=${LIMIT}`);
  const dataNoTranslate = getPostsByLanguage(data);

  const postPairs = dataNoTranslate.map((post, index) => {
    const { id, slug, date, tags, categories } = post;
    return {
      id,
      title: slug,
      categories,
      featureBlog: hasData(tags) && tags.includes(FEATURE_BLOG),
      date,
      vn: post,
      ...getMultiLanguagePostMapping(post, data, slug),
    };
  });

  //get all results
  return postPairs;
};

export const blogApiByID = async (blogId) => {
  const blog = await fetchByCategoryAndId(BLOG, blogId);

  let blogFullWidthTag;

  if (hasData(blog.tags)) {
    const allTags = await fetchTag();
    const existingTags = blog.tags;

    blogFullWidthTag = getTagsFromBlog(
      allTags,
      existingTags,
      "blog-full-width"
    );
  }

  const { id, date, slug, content, categories } = blog;
  const blogData = {
    id,
    date,
    title: slug,
    imageFullWidth: hasData(blogFullWidthTag) && Number(blogFullWidthTag[0]),
    categories,
    content,
  };

  return blogData;
};
