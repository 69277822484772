import "./App.css";

import Home from "./pages/Home";
import About from "./pages/About";
import Space from "./pages/Space";
import Blog from "./pages/Blog";
import NavBar from "./components/navbar/NavBar";
import AppBar from "@mui/material/AppBar";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import BlogDetail from "./pages/BlogDetail";
import Contact from "./pages/Contact";
import Error from "./subPages/Error";
import StickyCTA from "./components/StickyCTA";
import Ingredient from "./pages/Ingredient";
import IngredientDetail from "./pages/IngredientDetail";
import ContactFooter from "./components/contact/ContactFooter";
import { useSelector } from "react-redux";

function App() {
  const language = useSelector((state) => state.reducer.language);

  return (
    <div className="App">
      <StickyCTA />
      <AppBar style={{ backgroundColor: "transparent" }} elevation={0}>
        <NavBar language={language} />
      </AppBar>
      <div className="app-content">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="space" element={<Space />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:id" element={<BlogDetail />} />
          {/* <Route path="ingredient" element={<Ingredient />} /> */}
          {/* <Route path="ingredient/:id" element={<IngredientDetail />} /> */}
          <Route path="contact" element={<Contact />} />
        </Routes>
        <ContactFooter language={language} />
        <Footer />
      </div>
    </div>
  );
}

export default App;
